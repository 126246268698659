import {inject, Injectable} from '@angular/core';
import {of} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {EntityClassType} from '|api/commons';
import {
  FilterOperator,
  FilterTreeOperator,
  GlobalOperator,
  IczInMemoryDatasource,
  IczTableDataSource,
  mergeSearchParams,
  SearchParams
} from '@icz/angular-table';
import {CodebookSearchService} from '../../services/codebook-search.service';

import {formatAsLocalIsoDate} from '@icz/angular-form-elements';
import {CodebookService} from './codebook.service';

@Injectable({
  providedIn: 'root',
})
export class ClassificationService {

  private searchService = inject(CodebookSearchService);
  private codebookService = inject(CodebookService);

  getValidEntityClassesDataSource(validFor: Date, entityClassType: EntityClassType, entityClassIds?: Nullable<number[]>) {
    return this.getScheme(validFor).pipe(
      switchMap(
        scheme => {
          if (!scheme) {
            return of({schemeDataSource: new IczInMemoryDatasource(() => []), schemeName: '', schemeId: 0, validFrom: new Date().toISOString(), validTo: new Date().toISOString()});
          }
          else {
            return of({
              schemeDataSource: this.getEntityClassDataSource(
                scheme.id,
                entityClassType,
                entityClassIds,
              ),
              schemeName: scheme.name,
              schemeId: scheme.id,
              validFrom: scheme.validFrom,
              validTo: scheme.validTo,
            });
          }
        }
      )
    );
  }

  getAllEntityClassesDataSource(entityClassType: EntityClassType) {
    return this.getEntityClassDataSource(
      null,
      entityClassType,
    );
  }

  getValidAndPastEntityClassesDataSource(entityClassType: EntityClassType, classificationSchemeIds: Nullable<number[]>) {
    return this.getEntityClassDataSource(
      classificationSchemeIds,
      entityClassType,
    );
  }

  private getScheme(validAt: Nullable<Date>) {
    if (!validAt) {
      validAt = new Date();
    }

    return this.codebookService.classificationSchemes().pipe(
      map(classificationSchemes => {
        return classificationSchemes
          .toSorted((cs1, cs2) => (cs1.validFrom ?? 'z') < (cs2.validFrom ?? 'z') ? 1 : -1)
          .find(cs => cs.validFrom && cs.validFrom <= validAt!.toISOString());
      })
    );
  }

  /**
   * @param classificationSchemeIds - null means "all classification schemes"
   * @param entityClassType - null means "all entity class types"
   */
  private getEntityClassesByScheme(
    classificationSchemeIds: Nullable<number | number[]>,
    entityClassType: Nullable<EntityClassType>,
    searchParams: SearchParams = {filter: [], sort: [], size: 0, page: 0}
  ) {
    let defaultFilter: SearchParams = {filter: [], sort: [], size: 0, page: 0};
    if (classificationSchemeIds) {
      const hasClassificationSchemeFilter = searchParams.filter.findIndex(filter => filter.fieldName === 'classificationSchemeId') > -1;
      if (!hasClassificationSchemeFilter) {
        if (Array.isArray(classificationSchemeIds)) {
          defaultFilter = {
            filter: [{
              fieldName: 'classificationSchemeId',
              value: classificationSchemeIds.toString(),
              operator: FilterOperator.inSet,
            }],
            sort: [],
            size: 0,
            page: 0,
          };
        } else {
          defaultFilter = {
            filter: [{
              fieldName: 'classificationSchemeId',
              value: classificationSchemeIds.toString(),
              operator: FilterOperator.equals,
            }],
            sort: [],
            size: 0,
            page: 0,
          };
        }
      }
    }

    if (searchParams.fulltextSearchTerm?.trim()) {
      defaultFilter.complexFilter = {
        operator: FilterTreeOperator.OR,
        values: [
          {
            fieldName: 'name',
            value: searchParams.fulltextSearchTerm,
            operator: FilterOperator.contains,
            isCaseInsensitive: true,
          },
          {
            fieldName: 'fqcCode',
            value: searchParams.fulltextSearchTerm,
            operator: FilterOperator.contains,
            isCaseInsensitive: true,
          }
        ]
      };
    }

    if (entityClassType) {
      defaultFilter.filter.push({
        fieldName: 'type',
        value: entityClassType,
        operator: FilterOperator.equals,
      });
    }

    defaultFilter.globalOperator = GlobalOperator.and;

    return this.searchService.findEntityClasses(mergeSearchParams(defaultFilter, searchParams));
  }

  private getEntityClassDataSource(
    classificationSchemeIds: Nullable<number | number[]>,
    entityClassType: Nullable<EntityClassType>,
    entityClassIds?: Nullable<number[]>,
  ): IczTableDataSource<any> {
    return new IczTableDataSource(searchParams => {
      if (entityClassIds) {
        searchParams!.filter!.push({
          fieldName: 'entityClassId',
          operator: FilterOperator.inSet,
          value: String(entityClassIds),
        });
      }

      return this.getEntityClassesByScheme(
        classificationSchemeIds,
        entityClassType,
        searchParams,
      );
    });
  }

  private getSchemeSearchParams(validFor: Nullable<Date>) {
    if (!validFor) validFor = new Date();

    return {
      page: 0,
      size: 1,
      sort: [{fieldName: 'validFrom', descending: true}],
      filter: [{
        fieldName: 'validFrom',
        operator: FilterOperator.lessOrEqual,
        value: formatAsLocalIsoDate(validFor.toISOString()),
      }],
    };
  }
}
