@if (isSelectedEntityClassExpired) {
  <icz-alert
    severity="warning"
    class="mb-12" heading="Věcná skupina je z neplatného spisového plánu"
  ></icz-alert>
}
<ng-container [formGroup]="form">
  <div
    #overlay="cdkOverlayOrigin"
    cdkOverlayOrigin>
    <icz-form-popup-table-selector #tableSelector
                                   popupTitle="Vyhledejte a vyberte věcnou skupinu"
                                   [tableTag]="currentClassificationSchemeTag"
                                   tableId="entity-class-search"
                                   [label]="label"
                                   modelValueKey="id"
                                   viewValueTemplate="{fqcCode} {name}"
                                   [formControlName]="controlName"
                                   [tableTitle]="currentClassificationSchemeName"
                                   [dataSource]="dataSource"
                                   [selectableValuesDataSource]="validEntityClassesDataSource"
                                   [tableColumnsData]="entityClassSelectorSchema"
                                   [fieldDisabled]="fieldDisabled"
                                   [showFulltextSearch]="true"
                                   fulltextSearchFieldPlaceholder="Hledej podle kódu nebo názvu"
                                   [defaultFilterColumns]="filterColumns"
                                   [rightLabel]="(isSelectedEntityClass && showPopupDetails) ? 'Náhled věcné skupiny' : undefined"
    >
      <ng-container rightLabelPopup>
        <icz-entity-class-selector-popup [selectedEntityClass]="selectedEntityClass"></icz-entity-class-selector-popup>
      </ng-container>
    </icz-form-popup-table-selector>
  </div>
</ng-container>
