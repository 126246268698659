import {DestroyRef, inject, Injectable, Type} from '@angular/core';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {forkJoin, map, Observable, of} from 'rxjs';
import {switchMap, take} from 'rxjs/operators';
import {
  CirculationActivityType,
  CrossReferenceSpecializationType,
  DocumentState,
  EntityType,
  FileState,
  ObjectClass,
  SettlementMethod,
  SettlementType,
} from '|api/commons';
import {AuthorizedEntityType, DocumentAuthorizedOperation, FileAuthorizedOperation} from '|api/core';
import {
  ApiCrossReferenceService,
  ApiDocumentService,
  ApiFileService,
  ApiSettlementService,
  CrossReferenceWithDetailDto,
  DocumentDto,
  FileDto,
  OwnDocumentDto,
  ReceivedDocumentDto,
  RegisterDocumentInFilingRegisterResponseDto,
  SettlementDto,
} from '|api/document';
import {InternalNotificationKey} from '|api/notification';
import {ApiReceivedPaperConsignmentService} from '|api/sad';
import {AddToFileDialogComponent} from '../components/add-to-file-dialog/add-to-file-dialog.component';
import {
  HandForStatementDialogComponent,
} from '../components/hand-for-statement-dialog/hand-for-statement-dialog.component';
import {
  MoveToAnotherFileDialogComponent,
  MoveToAnotherFileDialogData,
  MoveToAnotherFileDialogResult,
} from '../components/move-to-another-file-dialog/move-to-another-file-dialog.component';
import {ReregisterDialogComponent} from '../components/reregister-dialog/reregister-dialog.component';
import {DocumentSettleDialogComponent} from '../components/document-settle-dialog/document-settle-dialog.component';
import {DocumentToolbarDisablers} from './document-toolbar-buttons.disablers';
import {ToolbarProvider} from '../../table-toolbar-buttons.utils';
import {AuthorizedButton, AuthorizedButtonService} from '../../authorized-button.service';
import {DocumentToastService, DocumentToastType} from '../../../../core/services/notifications/document-toast.service';
import {FileToastService, FileToastType} from '../../../../core/services/notifications/file-toast.service';
import {CirculationToastService} from '../../../../core/services/notifications/circulation-toast.service';
import {
  InternalNotificationBulkDocumentActionMessageCode,
  InternalNotificationMessageCode,
} from '../../../../core/services/notifications/internal-notification.enum';
import {Button} from '../../../button-collection/button-collection.component';
import {CommonToolbarDisablers} from './toolbar-common.disablers';
import {FileToolbarDisablers} from './file-toolbar-buttons.disablers';
import {HandoverDialogComponent} from '../components/handover-dialog/handover-dialog.component';
import {
  HandForApprovalDialogComponent,
} from '../components/hand-for-approval-dialog/hand-for-approval-dialog.component';
import {
  HandForAcknowledgementDialogComponent,
} from '../components/hand-for-acknowledgement/hand-for-acknowledgement-dialog.component';
import {AbstractHandoverFormModalComponent} from '../components/abstract-handover-form-modal.component';
import {entityTypeToEntityTypeClass} from './task-toolbar-buttons.service';
import {
  HandoverEntity,
  HandoverToExternalAppDialogComponent,
  HandoverToExternalAppDialogData,
} from '../../handover-to-external-app-dialog/handover-to-external-app-dialog.component';
import {
  CirculationView,
  DocumentView,
  openGeneralCirculationModal,
  RegistryOfficeTransferView,
  StorageUnitView
} from './toolbar-common.utils';
import {DialogItem, DialogService, DialogSeverity, IczModalService} from '@icz/angular-modal';
import {ApplicationConfigService} from '../../../../core/services/config/application-config.service';
import {CurrentSessionService} from '../../../../services/current-session.service';
import {OrganizationalStructureService} from '../../../../core/services/organizational-structure.service';
import {WebSocketNotificationsService} from '../../../notifications/web-socket-notifications.service';
import {
  getObjectIcon,
  getObjectLabel,
  isDocumentEntity,
  isDocumentObject,
  isFileObject
} from '../../shared-document.utils';
import {createAbsoluteRoute} from '../../../../core/routing/routing.helpers';
import {ActionRoute, ApplicationRoute} from '../../../../enums/shared-routes.enum';
import {DocumentDetailRoute, DocumentsRoute, FilingOfficeRoute} from '../../../../enums/documents-routes.enum';
import {DocumentDtoWithAuthorization} from '../../model/dto-with-permissions.interface';
import {DocumentSearchService} from '../../../../services/document-search.service';
import {DocumentDetailService} from '../../../../services/document-detail.service';
import {FileDetailCountType, FileDetailService} from '../../../../services/file-detail.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {PrintService} from '../../print.service';
import {PrintingOperationType} from '../../../../services/user-settings.service';
import {CodebookService} from '../../../../core/services/codebook.service';
import {GeneralAuthorizedOperation} from '../../permissions/permissions.utils';
import {
  BulkOperationValidationService,
  OperationValidator,
} from '../../../../services/bulk-operation-validation.service';
import {DocumentOperationValidators} from './document-operation-validators';
import {FileOperationValidators} from './file-operation-validators';
import {CounterTypeGroup, MainMenuCountsService} from '../../../../core/services/main-menu-counts.service';
import {decrement} from '../../../../lib/object-counts';
import {RemoteBinaryFileDownloadService} from '../../../../services/remote-binary-file-download.service';
import {LocalBinaryFileDownloadService} from '../../../../services/local-binary-file-download.service';
import {GlobalLoadingIndicatorService} from '@icz/angular-essentials';
import {NoOfficialContentDialogComponent} from '../../no-official-content-dialog/no-official-content-dialog.component';
import {CommonToolbarValidators} from './toolbar-common.validators';
import {
  PrintDocumentInfoDialogComponent
} from '../components/print-document-rendition-dialog/print-document-info-dialog.component';
import {CirculationActivityDto} from '|api/flow';
import {
  CreateEmailConsignmentDialogComponent
} from '../../create-email-consignment-dialog/create-email-consignment-dialog.component';
import {LabelTemplatesConfigurationKeys} from '../../../../core/services/config/config-keys.enum';
import {ConfigPropValueService} from 'libs/shared/src/lib/services';
import {FilterOperator, SearchParams} from '@icz/angular-table';
import {WITHOUT_REF_NUMBER} from '../../shared-business-components.model';
import {esslErrorDtoToToastParameters} from '../../../notifications/toast.service';
import {SubjectPropagateToFileService} from '../../subjects/subject-propagate-to-file.service';

export enum DocumentToolbarAction {
  DOCUMENT_REGISTERED = 'DOCUMENT_REGISTERED',
  DOCUMENT_REREGISTERED = 'DOCUMENT_REREGISTERED',
  DOCUMENT_DEACTIVATED = 'DOCUMENT_DEACTIVATED',
  DOCUMENT_REACTIVATED = 'DOCUMENT_REACTIVATED',
  DOCUMENT_HANDED_OVER_TO_EXTERNAL_APP = 'DOCUMENT_HANDED_OVER_TO_EXTERNAL_APP',
  DOCUMENT_SETTLED = 'DOCUMENT_SETTLED',
  DOCUMENT_ADDED_TO_FILE = 'DOCUMENT_ADDED_TO_FILE',
  DOCUMENT_REMOVED_FROM_FILE = 'DOCUMENT_REMOVED_FROM_FILE',
  DOCUMENT_MOVED_TO_ANOTHER_FILE = 'DOCUMENT_MOVED_TO_ANOTHER_FILE',
  DOCUMENT_SETTLEMENT_WITHDRAWN = 'DOCUMENT_SETTLEMENT_WITHDRAWN',
  DOCUMENT_CIRCULATION_STARTED = 'DOCUMENT_CIRCULATION_STARTED',
  DOCUMENT_SETTLE_STARTED = 'DOCUMENT_SETTLE_STARTED',
  ENTITY_EXCLUDED_FROM_FILLER = 'ENTITY_EXCLUDED_FROM_FILLER',
}

enum DocumentOperation {
  ADD_TO_FILE = 'ADD_TO_FILE',
  REMOVE_FROM_FILE = 'REMOVE_FROM_FILE',
  REGISTER_DOCUMENT = 'REGISTER_DOCUMENT',
  SETTLE_DOCUMENT = 'SETTLE_DOCUMENT',
  WITHDRAW_SETTLE_DOCUMENT = 'WITHDRAW_SETTLE_DOCUMENT',
}

export interface DocumentToolbarContext {
  viewType: DocumentView | CirculationView | StorageUnitView | RegistryOfficeTransferView,
  fileDetailService?: Nullable<FileDetailService>,
  file?: Nullable<FileDto>,
  runningActivity?: Nullable<CirculationActivityDto>,
}

@Injectable()
export class DocumentToolbarButtonsService extends ToolbarProvider<DocumentDto, DocumentToolbarAction, DocumentToolbarContext> {

  private globalLoading = inject(GlobalLoadingIndicatorService);
  private router = inject(Router);
  private modalService = inject(IczModalService);
  private searchService = inject(DocumentSearchService);
  private translateService = inject(TranslateService);
  private authorizedButtonService = inject(AuthorizedButtonService);
  private apiDocumentService = inject(ApiDocumentService);
  private documentSearchService = inject(DocumentSearchService);
  private apiFileService = inject(ApiFileService);
  private apiSettlementService = inject(ApiSettlementService);
  private applicationConfigService = inject(ApplicationConfigService);
  private documentToastService = inject(DocumentToastService);
  private fileToastService = inject(FileToastService);
  private circulationToastService = inject(CirculationToastService);
  private apiReceivedPaperConsignmentService = inject(ApiReceivedPaperConsignmentService);
  private apiCrossReferenceService = inject(ApiCrossReferenceService);
  private dialogService = inject(DialogService);
  private currentSessionService = inject(CurrentSessionService);
  private subjectPropagateToFileService = inject(SubjectPropagateToFileService);
  private organizationalStructureService = inject(OrganizationalStructureService);
  private wsNotificationService = inject(WebSocketNotificationsService);
  private printService = inject(PrintService);
  private destroyRef = inject(DestroyRef);
  private codebook = inject(CodebookService);
  private bulkOperationValidationService = inject(BulkOperationValidationService);
  private mainMenuCountsService = inject(MainMenuCountsService);
  private remoteBinaryFileDownloadService = inject(RemoteBinaryFileDownloadService);
  private localBinaryFileDownloadService = inject(LocalBinaryFileDownloadService);
  private configPropValueService = inject(ConfigPropValueService);
  private documentDetailService = inject(DocumentDetailService, {optional: true});

  constructor() {
    super();

    this.wsNotificationService.getMessageListener$(InternalNotificationMessageCode.DOCUMENT_SETTLEMENT_SUCCESS)
      .pipe(takeUntilDestroyed(this.destroyRef)).subscribe(_ => {
        this.mainMenuCountsService.updateMainMenuCounters([CounterTypeGroup.DOCUMENT_FILE_TASKS_RECEIVED_CONSIGNMENTS_TRANSFERS_COUNTS]);
        this.announceActionCompleted(DocumentToolbarAction.DOCUMENT_SETTLED);
      }
    );

    this.wsNotificationService.getMessageListener$(InternalNotificationMessageCode.DOCUMENT_SETTLEMENT_ERROR)
      .pipe(takeUntilDestroyed(this.destroyRef)).subscribe(_ => {
        this.mainMenuCountsService.updateMainMenuCounters([CounterTypeGroup.DOCUMENT_FILE_TASKS_RECEIVED_CONSIGNMENTS_TRANSFERS_COUNTS]);
        this.announceActionCompleted(DocumentToolbarAction.DOCUMENT_SETTLED);
      }
    );

    this.wsNotificationService.getMessageListener$(InternalNotificationBulkDocumentActionMessageCode.BULK_WITHDRAW_SETTLE_DOCUMENT_SUCCESS)
      .pipe(takeUntilDestroyed(this.destroyRef)).subscribe(_ => {
        this.mainMenuCountsService.updateMainMenuCounters([CounterTypeGroup.DOCUMENT_FILE_TASKS_RECEIVED_CONSIGNMENTS_TRANSFERS_COUNTS]);
        this.announceActionCompleted(DocumentToolbarAction.DOCUMENT_SETTLEMENT_WITHDRAWN);
      }
    );

    this.wsNotificationService.getMessagesListener$([InternalNotificationMessageCode.BULK_DOCUMENT_HANDOVER_SUCCESS, InternalNotificationMessageCode.BULK_FILE_HANDOVER_SUCCESS])
      .pipe(takeUntilDestroyed(this.destroyRef)).subscribe(_ => {
        this.mainMenuCountsService.updateMainMenuCounters([CounterTypeGroup.DOCUMENT_FILE_TASKS_RECEIVED_CONSIGNMENTS_TRANSFERS_COUNTS]);
        this.announceActionCompleted(DocumentToolbarAction.DOCUMENT_CIRCULATION_STARTED);
      }
    );

    this.wsNotificationService.getMessagesListener$([
      InternalNotificationBulkDocumentActionMessageCode.BULK_INSERT_DOCUMENTS_INTO_FILE_SUCCESS,
      InternalNotificationBulkDocumentActionMessageCode.BULK_MOVE_DOCUMENTS_TO_ANOTHER_FILE_SUCCESS,
      InternalNotificationBulkDocumentActionMessageCode.BULK_REGISTER_DOCUMENT_SUCCESS,
      InternalNotificationBulkDocumentActionMessageCode.BULK_REMOVE_DOCUMENTS_FROM_FILE_SUCCESS,
    ])
      .pipe(takeUntilDestroyed(this.destroyRef)).subscribe(message => {
        this.mainMenuCountsService.updateMainMenuCounters([CounterTypeGroup.DOCUMENT_FILE_TASKS_RECEIVED_CONSIGNMENTS_TRANSFERS_COUNTS]);
        if (message.messageCode === InternalNotificationBulkDocumentActionMessageCode.BULK_INSERT_DOCUMENTS_INTO_FILE_SUCCESS) {
          this.announceActionCompleted(DocumentToolbarAction.DOCUMENT_ADDED_TO_FILE);
        } else if (message.messageCode === InternalNotificationBulkDocumentActionMessageCode.BULK_MOVE_DOCUMENTS_TO_ANOTHER_FILE_SUCCESS) {
          this.announceActionCompleted(DocumentToolbarAction.DOCUMENT_MOVED_TO_ANOTHER_FILE);
        } else if (message.messageCode === InternalNotificationBulkDocumentActionMessageCode.BULK_REGISTER_DOCUMENT_SUCCESS) {
          this.announceActionCompleted(DocumentToolbarAction.DOCUMENT_REGISTERED);
        } else if (message.messageCode === InternalNotificationBulkDocumentActionMessageCode.BULK_REMOVE_DOCUMENTS_FROM_FILE_SUCCESS) {
          this.announceActionCompleted(DocumentToolbarAction.DOCUMENT_REMOVED_FROM_FILE);
        }
      }
    );
  }

  // entityContext is array or entities extended with permissions because of new AbstractPermissionedEntityDatasource
  getToolbarButtons(
    selection: DocumentDtoWithAuthorization[],
    context: DocumentToolbarContext,
  ): Observable<Button[]> {
    const isAnyCirculationAllowed =
      this.applicationConfigService.allowHandForResolution ||
      this.applicationConfigService.allowHandForApproval ||
      this.applicationConfigService.allowHandForStatement ||
      this.applicationConfigService.allowHandForAcknowledgement;

    const isOnDocumentDetail = this.documentDetailService !== null;

    const singleEntity = selection?.[0] ?? null;

    const isDocumentDeactivated = singleEntity?.documentState === DocumentState.DEACTIVATED;
    const isDocumentRegistered = Boolean(singleEntity?.refNumber);

    let buttons: AuthorizedButton[] = [];

    const menuMoreButtons: AuthorizedButton = {
      label: 'Více',
      icon: 'more',
      buttonDisablers: [
        DocumentToolbarDisablers.isDocumentDeactivated(selection),
      ],
      submenuItems: [
        {
          label: 'Tisk štítku dokumentu',
          buttonDisablers: [
            CommonToolbarDisablers.isNoOrMultipleItemsSelected(selection),
            DocumentToolbarDisablers.selectedItemIsNotDocument(selection),
            DocumentToolbarDisablers.documentIsNotAnalogOrHybrid(selection),
          ],
          action: () => this.onPrintDocumentLabel(selection[0])
        },
        {
          label: 'Tisk štítku obálky',
          buttonDisablers: [
            CommonToolbarDisablers.isNoOrMultipleItemsSelected(selection),
            DocumentToolbarDisablers.selectedItemIsNotDocument(selection),
            DocumentToolbarDisablers.isNotReceivedDocument(selection),
          ],
          action: () => this.onPrintConsignmentLabel(selection[0]),
        },
        {
          label: 'Tisk potvrzení podání',
          buttonDisablers: [
            CommonToolbarDisablers.isNoOrMultipleItemsSelected(selection),
            DocumentToolbarDisablers.selectedItemIsNotDocument(selection),
            DocumentToolbarDisablers.isNotReceivedDocument(selection),
          ],
          action: () => this.ondDownloadPdfProofOfDelivery(selection[0]),
        },
        {
          label: 'Tisk informací o dokumentu',
          buttonDisablers: [
            CommonToolbarDisablers.isNoItemSelected(selection),
            DocumentToolbarDisablers.selectedItemIsNotDocument(selection),
          ],
          action: () => this.onPrintDocumentInfo(selection),
        },
      ]
    };

    if (context.viewType === DocumentView.STORAGE_UNIT_CONTENTS) {
      buttons = [menuMoreButtons];
    } else if (context.viewType === DocumentView.FILING_OFFICE_REJECTED) {
      buttons = [menuMoreButtons];
    } else if (context.viewType === DocumentView.FILING_OFFICE_HANDED_OVER) {
      buttons = [menuMoreButtons];
    } else {
      buttons = [
        {
          label: 'Založit',
          icon: 'add_new',
          show: context.viewType !== DocumentView.SETTLED_DOCUMENTS,
          buttonDisablers: [
            DocumentToolbarDisablers.isParentFileDeactivated(selection, context.fileDetailService, context.file),
            DocumentToolbarDisablers.isDocumentDeactivated(selection),
          ],
          submenuItems: [
            {
              label: 'Založit vlastní dokument',
              show: [DocumentView.DOCUMENT_LISTING, DocumentView.EXPIRING_DOCUMENTS].includes(context.viewType as DocumentView),
              action: () => this.onNewOwnDocumentClick(),
            },
            {
              label: 'Založit doručený dokument',
              show: [DocumentView.DOCUMENT_LISTING, DocumentView.EXPIRING_DOCUMENTS].includes(context.viewType as DocumentView),
              action: () => this.onNewReceivedDocumentClick(),
            },
            {
              label: 'Založit odpověď (vyřizující dokument)',
              authorizedOperations: [DocumentAuthorizedOperation.DOCUMENT_CREATE_RECEIVED_DOCUMENT_RESPONSE],
              buttonDisablers: [
                CommonToolbarDisablers.isNoOrMultipleItemsSelected(selection),
                DocumentToolbarDisablers.selectedItemIsNotDocument(selection),
                DocumentToolbarDisablers.isNotReceivedDocument(selection),
                DocumentToolbarDisablers.documentIsNotRegistered(selection),
                DocumentToolbarDisablers.isDocumentSettled(selection),
                DocumentToolbarDisablers.isNotDocumentOwnerOrSupervisor(this.currentSessionService, this.organizationalStructureService, selection),
                DocumentToolbarDisablers.isDocumentUnopened(selection as ReceivedDocumentDto[]),
                CommonToolbarDisablers.isEsslObjectLocked(selection),
              ],
              show: context.viewType !== DocumentView.SETTLED_DOCUMENTS,
              action: () => this.onNewResponseDocumentClick(selection[0]),
            },
            {
              label: 'Založit spis nad dokumentem',
              authorizedOperations: [DocumentAuthorizedOperation.DOCUMENT_CREATE_FILE],
              buttonDisablers: [
                CommonToolbarDisablers.isNoOrMultipleItemsSelected(selection),
                DocumentToolbarDisablers.selectedItemIsNotDocument(selection),
                DocumentToolbarDisablers.documentIsNotRegistered(selection),
                DocumentToolbarDisablers.isDocumentInFile(selection),
                DocumentToolbarDisablers.isDocumentInStorageUnit(selection),
                DocumentToolbarDisablers.isNotDocumentOwnerOrSupervisor(this.currentSessionService, this.organizationalStructureService, selection),
                DocumentToolbarDisablers.documentInCirculation(context.runningActivity, selection),
                DocumentToolbarDisablers.documentIsInExternalApplication(selection),
                CommonToolbarDisablers.isEsslObjectLocked(selection)
              ],
              action: () => this.onNewFileClick(selection[0]),
            },
            {
              label: 'Duplikát dokumentu',
              authorizedOperations: [DocumentAuthorizedOperation.DOCUMENT_DUPLICATE],
              buttonDisablers: [
                CommonToolbarDisablers.isNoOrMultipleItemsSelected(selection),
                DocumentToolbarDisablers.selectedItemIsNotDocument(selection),
                DocumentToolbarDisablers.isDocumentUnopened(selection as ReceivedDocumentDto[]),
                CommonToolbarDisablers.isEsslObjectLocked(selection),
              ],
              action: () => this.onDocumentDuplicateClick(selection[0]),
            },
            {
              label: 'E-mail mimo podatelnu',
              show: [DocumentView.DOCUMENT_LISTING, DocumentView.EXPIRING_DOCUMENTS].includes(context.viewType as DocumentView),
              action: () => this.onCreateEmailConsignmentClick(),
            },
          ],
        },
        {
          label: 'Úpravy',
          icon: 'edit',
          show: !isDocumentDeactivated,
          buttonDisablers: [
            CommonToolbarDisablers.isNoItemSelected(selection),
            DocumentToolbarDisablers.isDocumentDeactivated(selection),
            DocumentToolbarDisablers.isParentFileDeactivated(selection, context.fileDetailService, context.file),
            CommonToolbarDisablers.isEsslObjectLocked(selection),
          ],
          submenuItems: [
            {
              label: 'Vložit do spisu',
              authorizedOperations: [DocumentAuthorizedOperation.DOCUMENT_CREATE_FILE],
              buttonDisablers: [
                CommonToolbarDisablers.isNoItemSelected(selection),
                DocumentToolbarDisablers.selectedItemIsNotDocument(selection),
                DocumentToolbarDisablers.isDocumentInFile(selection),
                DocumentToolbarDisablers.isDocumentToOwnHands(selection as ReceivedDocumentDto[]),
                DocumentToolbarDisablers.isDocumentInStorageUnit(selection),
                DocumentToolbarDisablers.documentInCirculation(context.runningActivity, selection),
                DocumentToolbarDisablers.documentIsInExternalApplication(selection),
                CommonToolbarDisablers.isEntityInRegistryOffice(selection),
              ],
              show: context.viewType !== DocumentView.SETTLED_DOCUMENTS,
              action: button => this.onDocumentOperationClick(
                selection,
                DocumentOperation.ADD_TO_FILE,
                'Některé objekty ({{errorCount}}) není možné přidat do spisu. Operace přidání do spisu bude provedena pro vyhovující dokumenty ({{successCount}}).',
                [
                  // operation Vložit do spisu can run with documents and one file, therefore bulk validation must be done in type safe way
                  CommonToolbarValidators.documentTypeSafeValidation(DocumentOperationValidators.isDocumentInFile()),
                  CommonToolbarValidators.documentTypeSafeValidation(DocumentOperationValidators.isDocumentInStorageUnit()),
                  CommonToolbarValidators.documentTypeSafeValidation(DocumentOperationValidators.documentIsInExternalApplication()),
                  CommonToolbarValidators.documentTypeSafeValidation(DocumentOperationValidators.isDocumentToOwnHands()),
                  CommonToolbarValidators.isEsslObjectLocked(),
                  CommonToolbarValidators.isEntityInRegistryOffice()
                ],
                button.authorizedOperations
              ),
            },
            {
              label: 'Vyjmout ze spisu',
              authorizedOperations: [DocumentAuthorizedOperation.DOCUMENT_REMOVE_FROM_FILE],
              show: context.viewType === DocumentView.FILE_CONTENTS || context.viewType === DocumentView.DOCUMENT_DETAIL,
              buttonDisablers: [
                CommonToolbarDisablers.isNoItemSelected(selection),
                DocumentToolbarDisablers.isParentFileClosed(selection, context.fileDetailService, context.file),
                DocumentToolbarDisablers.selectedItemIsNotDocument(selection),
                DocumentToolbarDisablers.isDocumentNotInFile(selection, context.viewType as DocumentView),
                DocumentToolbarDisablers.isDocumentToOwnHands(selection as ReceivedDocumentDto[]),
                CommonToolbarDisablers.isEsslObjectLocked(selection),
              ],
              action: button => this.onRemoveFromFileClick(
                selection,
                context.viewType as DocumentView,
                button.authorizedOperations!,
                false,
                context.fileDetailService!
              ),
            },
            {
              label: 'Přesunout do jiného spisu',
              authorizedOperations: [DocumentAuthorizedOperation.DOCUMENT_REMOVE_FROM_FILE],
              show: context.viewType === DocumentView.FILE_CONTENTS,
              buttonDisablers: [
                CommonToolbarDisablers.isNoItemSelected(selection),
                DocumentToolbarDisablers.isParentFileDeactivated(selection, context.fileDetailService, context.file),
                DocumentToolbarDisablers.selectedItemIsNotDocument(selection),
                DocumentToolbarDisablers.isDocumentNotInFile(selection, context.viewType as DocumentView),
                DocumentToolbarDisablers.isDocumentToOwnHands(selection as ReceivedDocumentDto[]),
                CommonToolbarDisablers.isEsslObjectLocked(selection),
              ],
              action: button => this.onRemoveFromFileClick(
                selection,
                context.viewType as DocumentView,
                button.authorizedOperations!,
                true,
                context.fileDetailService!
              ),
            },
            {
              label: 'Vyřídit dokument',
              authorizedOperations: [DocumentAuthorizedOperation.DOCUMENT_SETTLE],
              buttonDisablers: [
                CommonToolbarDisablers.isNoItemSelected(selection),
                DocumentToolbarDisablers.selectedItemIsNotDocument(selection),
                DocumentToolbarDisablers.isDocumentSettled(selection),
                DocumentToolbarDisablers.documentIsNotRegistered(selection),
                DocumentToolbarDisablers.isDocumentUnopened(selection as ReceivedDocumentDto[]),
                CommonToolbarDisablers.isEsslObjectLocked(selection),
                CommonToolbarDisablers.isFileOrDocumentSuspended(selection)
              ],
              show: context.viewType !== DocumentView.SETTLED_DOCUMENTS,
              action: button => this.onDocumentOperationClick(
                selection,
                DocumentOperation.SETTLE_DOCUMENT,
                'Některé dokumenty ({{errorCount}}) není možné vyřídit. Hromadná akce vyřizení bude provedena jen s vyhovujícími dokumenty ({{successCount}}).',
                [
                  DocumentOperationValidators.selectedItemIsNotDocument(),
                  DocumentOperationValidators.isDocumentSettled(),
                  DocumentOperationValidators.documentIsNotRegistered(),
                  DocumentOperationValidators.isDocumentUnopened() as OperationValidator<DocumentDto>,
                  CommonToolbarValidators.isEsslObjectLocked(),
                  CommonToolbarValidators.isFileOrDocumentSuspended()
                ],
                button.authorizedOperations
              ),
            },
            {
              label: 'Zrušit vyřízení dokumentu',
              authorizedOperations: [DocumentAuthorizedOperation.DOCUMENT_REVOKE_SETTLEMENT],
              buttonDisablers: [
                CommonToolbarDisablers.isNoItemSelected(selection),
                DocumentToolbarDisablers.selectedItemIsNotDocument(selection),
                DocumentToolbarDisablers.isDocumentNotSettled(selection),
                DocumentToolbarDisablers.isDocumentUnopened(selection as ReceivedDocumentDto[]),
                DocumentToolbarDisablers.isDocumentInStorageUnit(selection),
                DocumentToolbarDisablers.isParentFileClosed(selection, context.fileDetailService, context.file),
                CommonToolbarDisablers.isEntityInRegistryOffice(selection),
                CommonToolbarDisablers.isEsslObjectLocked(selection),
              ],
              show: context.viewType === DocumentView.SETTLED_DOCUMENTS || context.viewType === DocumentView.FILE_CONTENTS || isOnDocumentDetail || context.viewType === DocumentView.MANUAL_SHARED,
              action: button => this.onDocumentOperationClick(
                selection,
                DocumentOperation.WITHDRAW_SETTLE_DOCUMENT,
                'U některých dokumentů ({{errorCount}}) není možné zrušit vyřízení. Hromadná akce bude provedena jen s vyhovujícími dokumenty ({{successCount}}).',
                [
                  DocumentOperationValidators.selectedItemIsNotDocument(),
                  DocumentOperationValidators.isDocumentNotSettled(),
                  DocumentOperationValidators.isParentFileClosed(context.fileDetailService, context.file),
                  CommonToolbarValidators.isEntityInRegistryOffice(),
                  DocumentOperationValidators.isDocumentUnopened() as OperationValidator<DocumentDto>,
                ],
                button.authorizedOperations
              ),
            },
            {
              label: 'Zaevidování dokumentu',
              show: context.viewType !== DocumentView.SETTLED_DOCUMENTS,
              authorizedOperations: [DocumentAuthorizedOperation.DOCUMENT_REGISTER],
              buttonDisablers: [
                CommonToolbarDisablers.isNoItemSelected(selection),
                DocumentToolbarDisablers.selectedItemIsNotDocument(selection),
                DocumentToolbarDisablers.documentIsRegistered(selection),
                DocumentToolbarDisablers.isDocumentUnopened(selection as ReceivedDocumentDto[]),
                CommonToolbarDisablers.isEsslObjectLocked(selection),
              ],
              action: button => this.onDocumentOperationClick(
                selection,
                DocumentOperation.REGISTER_DOCUMENT,
                'Některé dokumenty ({{errorCount}}) není možné zaevidovat. Hromadná akce zaevidování bude provedena jen s vyhovujícími dokumenty ({{successCount}}).',
                [
                  DocumentOperationValidators.selectedItemIsNotDocument(),
                  DocumentOperationValidators.documentIsRegistered(),
                  CommonToolbarValidators.isEsslObjectLocked(),
                ],
                button.authorizedOperations
              ),
            },
            {
              label: 'Přeevidování dokumentu',
              authorizedOperations: [DocumentAuthorizedOperation.DOCUMENT_REGISTER],
              buttonDisablers: [
                CommonToolbarDisablers.isNoItemSelected(selection),
                DocumentToolbarDisablers.selectedItemIsNotDocument(selection),
                DocumentToolbarDisablers.documentIsNotRegistered(selection),
                DocumentToolbarDisablers.isDocumentUnopened(selection as ReceivedDocumentDto[]),
                CommonToolbarDisablers.isEsslObjectLocked(selection),
              ],
              show: isDocumentRegistered && (context.viewType !== DocumentView.FILE_CONTENTS),
              action: button => this.onReregisterClick(selection, button.authorizedOperations)
            },
            {
              label: 'Zaevidování obsahu',
              show: context.viewType !== DocumentView.SETTLED_DOCUMENTS,
              authorizedOperations: [DocumentAuthorizedOperation.DOCUMENT_MODIFY_PROFILE],
              buttonDisablers: [
                CommonToolbarDisablers.isNoOrMultipleItemsSelected(selection),
                DocumentToolbarDisablers.isNotReceivedDocument(selection),
                DocumentToolbarDisablers.isDocumentNotUnopened(selection as ReceivedDocumentDto[]),
                DocumentToolbarDisablers.documentInCirculation(context.runningActivity, selection),
                CommonToolbarDisablers.isEsslObjectLocked(selection),
              ],
              action: () => this.onRegisterContentOfficerClick(selection[0]),
            },
            {
              label: 'Nemá úřední obsah',
              show: context.viewType !== DocumentView.SETTLED_DOCUMENTS,
              authorizedOperations: [DocumentAuthorizedOperation.DOCUMENT_MODIFY_PROFILE],
              buttonDisablers: [
                CommonToolbarDisablers.isNoOrMultipleItemsSelected(selection),
                DocumentToolbarDisablers.isNotReceivedDocument(selection),
                DocumentToolbarDisablers.isDocumentNotToOwnHands(selection as ReceivedDocumentDto[]),
                DocumentToolbarDisablers.isDocumentNotUnopened(selection as ReceivedDocumentDto[]),
                CommonToolbarDisablers.isEsslObjectLocked(selection),
              ],
              action: () => this.onNoOfficialContentClick(selection),
            },
            {
              label: 'Nasdílení dokumentu',
              authorizedOperations: [DocumentAuthorizedOperation.DOCUMENT_SHOW_SHARING],
              buttonDisablers: [
                CommonToolbarDisablers.isNoOrMultipleItemsSelected(selection),
                DocumentToolbarDisablers.selectedItemIsNotDocument(selection),
                CommonToolbarDisablers.isEsslObjectLocked(selection),
              ],
              action: () => this.onShareClick(selection[0]),
            },
          ],
        },
        {
          label: 'Úpravy dokumentu',
          icon: 'edit',
          show: isDocumentDeactivated,
          submenuItems: [
            {
              label: 'Obnovit dokument',
              authorizedOperations: [DocumentAuthorizedOperation.DOCUMENT_REACTIVATE],
              buttonDisablers: [
                CommonToolbarDisablers.isNoOrMultipleItemsSelected(selection),
                DocumentToolbarDisablers.selectedItemIsNotDocument(selection),
                DocumentToolbarDisablers.isParentFileDeactivated(selection, context.fileDetailService, context.file),
                CommonToolbarDisablers.isEsslObjectLocked(selection),
              ],
              action: () => this.onReativationClick(selection[0]),
            },
          ]
        },
        {
          label: 'Oběh',
          icon: 'move_document',
          show: isAnyCirculationAllowed,
          submenuItems: [
            {
              label: 'Předat dokument',
              iconAfter: 'info',
              tooltip: 'fe.ui.circulation.settlement.tooltip',
              authorizedOperations: [DocumentAuthorizedOperation.DOCUMENT_CREATE_HANDOVER_ACTIVITY],
              show: this.applicationConfigService.allowHandForResolution,
              buttonDisablers: [
                CommonToolbarDisablers.isNoItemSelected(selection),
                DocumentToolbarDisablers.selectedItemIsNotDocument(selection),
                DocumentToolbarDisablers.isDocumentDeactivated(selection),
                DocumentToolbarDisablers.isDocumentUnopened(selection as ReceivedDocumentDto[]),
                DocumentToolbarDisablers.isDocumentInFile(selection),
                CommonToolbarDisablers.isEsslObjectLocked(selection),
              ],
              action: button => this.onCirculationOperationClick(
                'Předat - {{refNumber}}',
                'Hromadně předat ({{count}}) dokumentů',
                HandoverDialogComponent,
                CirculationActivityType.DOCUMENT_HANDOVER,
                'Pro některé objekty ({{errorCount}}) není možno provést předání. Hromadná akce předání bude provedena jen s vyhovujícími objekty ({{successCount}}).',
                selection,
                [
                  DocumentOperationValidators.selectedItemIsNotDocument(),
                  DocumentOperationValidators.isDocumentDeactivated(),
                  DocumentOperationValidators.isDocumentInFile(),
                  CommonToolbarValidators.isEsslObjectLocked(),
                ],
                button.authorizedOperations
              ),
            },
            {
              label: 'Dát ke schválení',
              iconAfter: 'info',
              tooltip: 'fe.ui.circulation.approval.tooltip',
              authorizedOperations: [DocumentAuthorizedOperation.DOCUMENT_CREATE_APPROVAL_ACTIVITY],
              show: this.applicationConfigService.allowHandForApproval,
              buttonDisablers: [
                CommonToolbarDisablers.isNoItemSelected(selection),
                DocumentToolbarDisablers.selectedItemIsNotDocument(selection),
                DocumentToolbarDisablers.isDocumentNotInProgress(selection),
                DocumentToolbarDisablers.isDocumentDeactivated(selection),
                DocumentToolbarDisablers.isDocumentUnopened(selection as ReceivedDocumentDto[]),
                CommonToolbarDisablers.isEsslObjectLocked(selection),
              ],
              action: button => this.onCirculationOperationClick(
                'Dát ke schválení - {{refNumber}}',
                'Hromadně dát ({{count}}) dokumentů ke schválení',
                HandForApprovalDialogComponent,
                CirculationActivityType.DOCUMENT_APPROVAL,
                'Některé objekty ({{errorCount}}) není možno dát ke schválení. Hromadná operace bude pokračovat s vyhovujícími objekty ({{successCount}}).',
                selection,
                [
                  DocumentOperationValidators.selectedItemIsNotDocument(),
                  DocumentOperationValidators.isDocumentNotInProgress(),
                  DocumentOperationValidators.isDocumentDeactivated(),
                  CommonToolbarValidators.isEsslObjectLocked(),
                ],
                button.authorizedOperations
              ),
            },
            {
              label: 'Dát k vyjádření',
              iconAfter: 'info',
              tooltip: 'fe.ui.circulation.statement.tooltip',
              authorizedOperations: [DocumentAuthorizedOperation.DOCUMENT_CREATE_STATEMENT_ACTIVITY],
              show: this.applicationConfigService.allowHandForStatement,
              buttonDisablers: [
                CommonToolbarDisablers.isNoItemSelected(selection),
                DocumentToolbarDisablers.selectedItemIsNotDocument(selection),
                DocumentToolbarDisablers.isDocumentNotInProgress(selection),
                DocumentToolbarDisablers.isDocumentDeactivated(selection),
                DocumentToolbarDisablers.isDocumentUnopened(selection as ReceivedDocumentDto[]),
                CommonToolbarDisablers.isEsslObjectLocked(selection),
              ],
              action: button => this.onCirculationOperationClick(
                'Dát k vyjádření - {{refNumber}}',
                'Hromadně dát ({{count}}) dokumentů k vyjádření',
                HandForStatementDialogComponent,
                CirculationActivityType.DOCUMENT_STATEMENT,
                'Některé objekty ({{errorCount}}) není možno dát k vyjádření. Hromadná operace bude pokračovat s vyhovujícími objekty ({{successCount}}).',
                selection,
                [
                  DocumentOperationValidators.selectedItemIsNotDocument(),
                  DocumentOperationValidators.isDocumentNotInProgress(),
                  DocumentOperationValidators.isDocumentDeactivated(),
                  DocumentOperationValidators.isDocumentUnopened() as OperationValidator<DocumentDto>,
                  CommonToolbarValidators.isEsslObjectLocked(),
                ],
                button.authorizedOperations
              ),
            },
            {
              label: 'Dát na vědomí',
              iconAfter: 'info',
              tooltip: 'fe.ui.circulation.acknowledgement.tooltip',
              authorizedOperations: [DocumentAuthorizedOperation.DOCUMENT_CREATE_ACKNOWLEDGEMENT_ACTIVITY],
              show: this.applicationConfigService.allowHandForAcknowledgement,
              buttonDisablers: [
                CommonToolbarDisablers.isNoItemSelected(selection),
                DocumentToolbarDisablers.selectedItemIsNotDocument(selection),
                DocumentToolbarDisablers.isDocumentDeactivated(selection),
                CommonToolbarDisablers.isEsslObjectLocked(selection),
              ],
              action: button => this.onCirculationOperationClick(
                'Dát na vědomí - {{refNumber}}',
                'Hromadně dát ({{count}}) dokumentů na vědomí',
                HandForAcknowledgementDialogComponent,
                CirculationActivityType.DOCUMENT_ACKNOWLEDGEMENT,
                'Některé objekty ({{errorCount}}) není možno dát na vědomí. Hromadná operace bude pokračovat s vyhovujícími objekty ({{successCount}}).',
                selection,
                [
                  DocumentOperationValidators.selectedItemIsNotDocument(),
                  DocumentOperationValidators.isDocumentDeactivated(),
                  DocumentOperationValidators.isDocumentUnopened() as OperationValidator<DocumentDto>,
                  CommonToolbarValidators.isEsslObjectLocked(),
                ],
                button.authorizedOperations
              ),
            },
            {
              label: 'Předat externí aplikaci',
              iconAfter: 'info',
              tooltip: 'fe.ui.circulation.externalHandover.tooltip',
              authorizedOperations: [DocumentAuthorizedOperation.DOCUMENT_PASS_ON_TO_EXTERNAL_ORGANIZATION, FileAuthorizedOperation.FILE_PASS_ON_TO_EXTERNAL_ORGANIZATION],
              buttonDisablers: [
                CommonToolbarDisablers.isNoItemSelected(selection),
                DocumentToolbarDisablers.isDocumentInFile(selection),
                DocumentToolbarDisablers.isDocumentInStorageUnit(selection),
                DocumentToolbarDisablers.documentHandoverToExternalApplicationPreconditionNotMet(selection),
                FileToolbarDisablers.fileHandoverToExternalApplicationPreconditionNotMet((selection as any[])),
                CommonToolbarDisablers.isEsslObjectLocked(selection),
              ],
              action: button => this.onHandoverToExternalAppClick(
                selection,
                [
                  DocumentOperationValidators.isDocumentInFile(),
                  DocumentOperationValidators.documentHandoverToExternalApplicationPreconditionNotMet(),
                  FileOperationValidators.fileHandoverToExternalApplicationPreconditionNotMet() as unknown as OperationValidator<DocumentDto>,
                  CommonToolbarValidators.isEsslObjectLocked(),
                ],
                button.authorizedOperations
              ),
            },
          ],
        },
        menuMoreButtons
      ];
    }

    let buttonObs: Observable<AuthorizedButton[]>;

    if (context.viewType === DocumentView.FILING_OFFICE_RECEIVED) {
      buttonObs = this.codebook.sheetNodesForCurrentFunctionalPosition().pipe(switchMap(
        sheetNodes => {
          return of([
            {
              label: 'Přijmout',
              icon: 'add_new',
              show: (sheetNodes.length > 0),
              action: () => {
                this.router.navigateByUrl(createAbsoluteRoute(ApplicationRoute.FILING_OFFICE, FilingOfficeRoute.RECEIVE_PAPER_CONSIGNMENT));
              },
            },
            {
              label: 'Osobní podání',
              icon: 'take_over',
              show: (sheetNodes.length > 0),
              action: () => {
                this.router.navigateByUrl(createAbsoluteRoute(ApplicationRoute.FILING_OFFICE, FilingOfficeRoute.RECEIVE_PERSONAL_CONSIGNMENT));
              },
            },
            menuMoreButtons
          ]);
        }
      ));
    } else {
      buttonObs = of(buttons);
    }

    // todo(mh) ESSL-10273 export this if else to *-toolbar-buttons.component.ts
    if (selection.length === 1) {
      return this.authorizedButtonService.fetchBulkAuthorizedButtonPermissions(
        isDocumentObject(selection[0]) ? {[AuthorizedEntityType.DOCUMENT]: [selection[0].id]} : {[AuthorizedEntityType.FILE]: [selection[0].id]},
        buttonObs,
      );
    } else {
      return buttonObs.pipe(map(buttons => this.authorizedButtonService.evaluateButtonDefinition(buttons)));
    }
  }

  onNewResponseDocumentClick(documentData: DocumentDto) {
    this.router.navigateByUrl(createAbsoluteRoute(
      ApplicationRoute.DOCUMENTS,
      documentData.id,
      DocumentsRoute.NEW_RESPONSE_DOCUMENT,
    ));
  }

  onNewOwnDocumentClick() {
    this.router.navigateByUrl(createAbsoluteRoute(ApplicationRoute.DOCUMENTS, ActionRoute.NEW));
  }

  onNewReceivedDocumentClick() {
    this.router.navigateByUrl(createAbsoluteRoute(ApplicationRoute.DOCUMENTS, DocumentsRoute.RECEIVED));
  }

  onCreateEmailConsignmentClick() {
    return this.modalService.openComponentInModal<boolean, void>({
      component: CreateEmailConsignmentDialogComponent,
      modalOptions: {
        width: 600,
        height: 800,
        titleTemplate: 'Předání e-mailové zprávy doručené mimo podatelnu',
      }
    });
  }

  // TODO add type control with implementation of this feature and show dialog, that document is not selected.
  onNewFileClick(documentData: DocumentDto) {
    this.router.navigateByUrl(createAbsoluteRoute(ApplicationRoute.DOCUMENTS, documentData.id, DocumentsRoute.FILE));
  }

  onDocumentDuplicateClick(documentData: DocumentDto) {
    this.router.navigateByUrl(createAbsoluteRoute(ApplicationRoute.DOCUMENTS, documentData.id, DocumentsRoute.DUPLICATE));
  }

  onRegisterContentOfficerClick(documentData: DocumentDto) {
    this.router.navigateByUrl(createAbsoluteRoute(ApplicationRoute.DOCUMENTS, documentData.id, DocumentsRoute.REGISTER_CONTENT_OFFICER));
  }

  onRegisterContentFilingOfficeClick(documentData: DocumentDto) {
    this.router.navigateByUrl(createAbsoluteRoute(ApplicationRoute.DOCUMENTS, documentData.id, DocumentsRoute.REGISTER_CONTENT_FILING_OFFICE));
  }

  onNoOfficialContentClick(documentData: DocumentDto[]) {
    const count = 1; // hardcoded because api supports just 1

    this.modalService
      .openComponentInModal({
        component: NoOfficialContentDialogComponent,
        modalOptions: {
          width: 800,
          height: 250,
          titleTemplate: (count > 1) ? 'Dokumenty nemají úřední obsah ({{count}})' : 'Dokument nemá úřední obsah',
          titleTemplateContext: {
            count: String(count)
          }
        },
        data: documentData,
      })
      .subscribe(result => {
        if (result) {
          // missing bulk deactivate endpoint from api!
          this.apiDocumentService.documentDeactivateDocument({id: documentData[0].id, body: {reason: ''}}).subscribe(_ => {
            this.mainMenuCountsService.updateMainMenuCounters([CounterTypeGroup.DOCUMENT_FILE_TASKS_RECEIVED_CONSIGNMENTS_TRANSFERS_COUNTS]);
            this.announceActionCompleted(DocumentToolbarAction.DOCUMENT_ADDED_TO_FILE);
          });
        }
      });
  }

  onAddToFileClick(selectedData: (DocumentDto | FileDto)[]) {
    const documents: DocumentDto[] = [];
    const files: FileDto[] = [];

    selectedData.forEach(object => {
      if (isDocumentEntity(object.entityType)) {
        documents.push(object as DocumentDto);
      } else {
        files.push(object as FileDto);
      }
    });

    if (files.length > 2) {
      this.dialogService.openSimpleDialog({
        title: 'Vložení dokumentů',
        content: [
          {text: 'Je vybrán více jak jeden spis pro vložení doumentů'},
        ],
        severity: DialogSeverity.ERROR
      });
    } else {
      const count = documents.length;
      this.modalService
      .openComponentInModal({
        component: AddToFileDialogComponent,
        modalOptions: {
          width: 500,
          height: 580,
          titleTemplate: (count > 1) ? 'Vložení dokumentů ({{count}}) do spisu' : 'Vložení dokumentu do spisu',
          titleTemplateContext: {
            count: String(count)
          }
        },
        data: {
          documents,
          file: (files.length === 1) ? files[0] : null,
        },
      })
      .subscribe(result => {
        if (result) {
          this.mainMenuCountsService.updateMainMenuCounters([CounterTypeGroup.DOCUMENT_FILE_TASKS_RECEIVED_CONSIGNMENTS_TRANSFERS_COUNTS]);
          this.announceActionCompleted(DocumentToolbarAction.DOCUMENT_ADDED_TO_FILE);
        }
      });
    }
  }

  onPrintDocumentLabel(documentData: DocumentDto) {
    this.globalLoading.doLoading(
      this.configPropValueService.getPropValuesForCurrentOrganization(
        [LabelTemplatesConfigurationKeys.PRINT_COMPONENT]
      ).pipe(
        switchMap(propertyValues => {
          const printComponents = propertyValues[LabelTemplatesConfigurationKeys.PRINT_COMPONENT].value as boolean;

          return this.apiDocumentService.documentPrintLabelBatch({body: {
            documentIds: [documentData.id],
            printComponents,
          }});
        }),
      )
    ).subscribe(labels => {
      this.printService.printText(PrintingOperationType.LABEL, labels).subscribe();
    });
  }

  onPrintConsignmentLabel(documentData: DocumentDto) {
    this.apiReceivedPaperConsignmentService.receivedPaperConsignmentPrintLabelByDocumentId({
      id: documentData.id
    }).subscribe(labels => {
      this.printService.printText(PrintingOperationType.LABEL, labels).subscribe();
    });
  }

  ondDownloadPdfProofOfDelivery(documentData: DocumentDto) {
    // note - inline non-blocking loading indicator could be nice here
    this.apiReceivedPaperConsignmentService
      .receivedPaperConsignmentFindConsignmentsForDocument({
        id: documentData!.id,
        page: 0,
        size: 1,
      }).subscribe(results => {
      this.remoteBinaryFileDownloadService.fetchReceivedConsignmentPdfProofOfDelivery(results.content![0].id!).subscribe(binaryWithCharset => {
        const fileName = `${this.translateService.instant('Potvrzení podání')} ${documentData.refNumber}`;
        this.localBinaryFileDownloadService.downloadBlob(binaryWithCharset.buffer, fileName, 'application/pdf');
      });
    });
  }

  removeFromFile(documentsToRemove: DocumentDto[], foundingDocumentRemoved: boolean, file: FileDto, fileDetailService?: FileDetailService) {
    if (documentsToRemove.length > 1) {
      this.globalLoading.doLoading(this.apiFileService.fileBulkremoveDocumentsFromFile({
        body: documentsToRemove.map(d => d.id)
      })).pipe(
        switchMap(_ => this.subjectPropagateToFileService.checkAndRemoveAllSubjectsOfDocumentFromFile(documentsToRemove.map(d => d.id), file.id)))
      .subscribe({
        next: _ => {
          this.documentToastService.dispatchBulkOperationStartedToast(DocumentToastType.DOCUMENT_BULK_REMOVE_FROM_FILE_STARTED,{
            [InternalNotificationKey.COUNT]: documentsToRemove.length
          });
        },
        error: _ => {
          this.documentToastService.dispatchSimpleErrorToast(DocumentToastType.DOCUMENT_BULK_ACTION_ERROR);
        }
      });
    } else {
      this.globalLoading.doLoading(this.apiFileService.fileRemoveDocumentFromFile({
        documentId: documentsToRemove[0].id
      }).pipe(
        switchMap(_ => this.subjectPropagateToFileService.checkAndRemoveAllSubjectsOfDocumentFromFile([documentsToRemove[0].id], file.id))))
        .subscribe(_ => {
          if (foundingDocumentRemoved) {
          this.fileToastService.dispatchFileDestroyed({
            [InternalNotificationKey.FILE_ID]: file.id,
            [InternalNotificationKey.FILE_SUBJECT]: file.subject,
          });
          this.router.navigateByUrl(createAbsoluteRoute(
            ApplicationRoute.DOCUMENTS,
            DocumentsRoute.DOCUMENT,
            documentsToRemove[0].id,
          ));
        }
        else {
          this.mainMenuCountsService.updateMainMenuCounters([CounterTypeGroup.DOCUMENT_FILE_TASKS_RECEIVED_CONSIGNMENTS_TRANSFERS_COUNTS]);
          this.announceActionCompleted(DocumentToolbarAction.DOCUMENT_REMOVED_FROM_FILE);
          fileDetailService?.reloadObject({
            [FileDetailCountType.CONTENT]: decrement(),
          });
        }

        this.documentToastService.dispatchDocumentRemovedFromFile({
          [InternalNotificationKey.DOCUMENT_ID]: documentsToRemove[0].id,
          [InternalNotificationKey.DOCUMENT_SUBJECT]: documentsToRemove[0].subject,
          [InternalNotificationKey.FILE_ID]: file.id,
          [InternalNotificationKey.FILE_SUBJECT]: file.subject
        });
      });
    }
  }

  onRemoveFromFileClick(documents: Array<DocumentDto>, viewType: DocumentView | CirculationView, authorizedOperations: GeneralAuthorizedOperation[], moveToAnotherFile: boolean, fileDetailService?: FileDetailService) {
    const removeFromFileValidators = [
      DocumentOperationValidators.selectedItemIsNotDocument(),
      DocumentOperationValidators.isDocumentNotInFile(viewType),
      DocumentOperationValidators.documentIsInExternalApplication()
    ];

    const file$ = fileDetailService ?
      (fileDetailService.object$.pipe(take(1)) as Observable<FileDto>) :
      this.apiFileService.fileFindById({id: documents[0].fileId!});

    this.globalLoading.doLoading(
      file$.pipe(
        switchMap(file => forkJoin([
          of(file),
          this.searchService.findFileDocuments(
            {
              filter: [],
              size: 0,
            },
            file.id
          )
        ]))
      )
    ).subscribe(([file, documentsInFile]) => {
      let isFileDelete = false;

      if (documents.length === documentsInFile.totalElements) {
        isFileDelete = true;
      }
      else {
        removeFromFileValidators.push(DocumentOperationValidators.documentIsFileInitialDocument(file.initialDocumentId));
      }

      this.bulkOperationValidationService.validateEsslObjects<DocumentDto>(
        {
          dialogWarningLabel: moveToAnotherFile ? 'Některé dokumenty ({{errorCount}}) není možné přesunout. Operace přesunutí bude provedena pro vyhovující dokumenty ({{successCount}}).' : 'Některé dokumenty ({{errorCount}}) není možné vyjmout ze spisu. Operace vyjmoutí ze spisu bude provedena pro vyhovující dokumenty ({{successCount}}).',
          dialogWarningLabelContext: {},
          operationValidators: removeFromFileValidators,
          esslObjects: documents.map(dd => this.esslObjectToValidationObject(dd)),
          authorizedOperations,
        },
        this,
      ).subscribe(() => {
        const dialogContents: DialogItem[] = [];

        this.globalLoading.doLoading(
          this.apiCrossReferenceService.crossReferenceRetrieveDocumentSettlementCrossReferences({
            body: documents.map(d => d.id)
          })
        ).subscribe(
          (crossReferences: CrossReferenceWithDetailDto[]) => {
            documents.forEach(doc => {
              if (doc.documentState === DocumentState.SETTLED) {
                dialogContents.push({
                  text: moveToAnotherFile ? 'Dokument {{refNumber}} {{subject}} je ve stavu Vyřízen a přesunutím ze spisu se znovu otevře.' : 'Dokument {{refNumber}} {{subject}} je ve stavu Vyřízen a vyjmutím ze spisu se znovu otevře.' ,
                  context: {refNumber: doc.refNumber, subject: doc.subject}});
              }

              const textPrefix = 'Dokument {{refNumber}} {{subject}} je spojen křížovým odkazem Vyřízení dokumentu dokumentem.';
              if (crossReferences.some(cr => (cr.entityReferringFromId === doc.id && cr.valid && cr.specialization === CrossReferenceSpecializationType.DOCUMENT_SETTLEMENT_BY_RESPONSE) )) {
                dialogContents.push({
                  text: moveToAnotherFile ? `${textPrefix} Přesunem dokumentu dojde ke zrušení tohoto křížového odkazu.` : `${textPrefix} Vyjmutím dokumentu dojde ke zrušení tohoto křížového odkazu.`,
                  context: {refNumber: doc.refNumber, subject: doc.subject}});
              }
            });

            const removeFromFile = () => {
              if (isFileDelete) {
                this.dialogService.openQuestionDialog(
                  {
                    title: moveToAnotherFile ? 'Přesunutí zakládajícího dokumentu ze spisu' : 'Vyjmutí zakládajícího dokumentu ze spisu',
                    question: moveToAnotherFile ? 'Opravdu chcete přesunout zakládající dokument ze spisu?' : 'Opravdu chcete vyjmout zakládající dokument ze spisu?',
                    description: moveToAnotherFile ? 'Chystáte se přesunout zakládající dokument ze spisu. Přesunem zakládajícího dokumentu dojde ke zničení tohoto spisu.' : 'Chystáte se vyjmout zakládající dokument ze spisu. Vyjmutím zakládajícího dokumentu dojde ke zničení tohoto spisu.',
                    leftButtonTitle: moveToAnotherFile ? 'Přesunout' : 'Vyjmout',
                  }
                ).subscribe(_ => {
                  if (moveToAnotherFile) {
                    this.onMoveToAnotherFileClick(documents, isFileDelete, file, fileDetailService);
                  } else {
                    this.removeFromFile(documents, isFileDelete, file, fileDetailService);
                  }
                });
              } else {
                if (moveToAnotherFile) {
                  this.onMoveToAnotherFileClick(documents, isFileDelete, file, fileDetailService);
                } else {
                  this.removeFromFile(documents, isFileDelete, file, fileDetailService);
                }
              }
            };

            if (dialogContents.length) {
              this.dialogService.openSimpleDialog({
                title: moveToAnotherFile ? 'Přesunem některých dokumentů zneplatníte jejich stav' : 'Vyjmutím některých dokumentů zneplatníte jejich stav',
                content: dialogContents,
                leftButtonTitle: moveToAnotherFile ? 'Pokračovat v přesunu ze spisu' : 'Pokračovat ve vyjmutí ze spisu',
              }).subscribe(
                closeValue => {
                  if (closeValue) {
                    removeFromFile();
                  }
                });
            } else {
              removeFromFile();
            }
          }
        );
      });
    });
  }

  onMoveToAnotherFileClick(documents: DocumentDto[], isFileDelete: boolean, file: FileDto, fileDetailService?: FileDetailService) {
    this.modalService.openComponentInModal<MoveToAnotherFileDialogResult, MoveToAnotherFileDialogData>({
      component: MoveToAnotherFileDialogComponent,
      modalOptions: {
        width: 500,
        height: 580,
        titleTemplate: documents.length > 1 ? 'Přesun dokumentů ({{count}}) do jiného spisu' : 'Přesun dokumentu do jiného spisu',
        titleTemplateContext: {
          count: String(documents.length)
        }
      },
      data: {
        documents: documents as (OwnDocumentDto | ReceivedDocumentDto)[],
        currentFile: file,
        isFileDelete
      },
    }).subscribe(result => {
      if (result) {
        this.documentToastService.dispatchDocumentMovedToAnotherFile({
          [InternalNotificationKey.DOCUMENT_ID]: documents[0].id,
          [InternalNotificationKey.DOCUMENT_SUBJECT]: documents[0].subject,
          [InternalNotificationKey.FILE_ID]: result.targetFile.id,
          [InternalNotificationKey.FILE_SUBJECT]: result.targetFile.subject,
        });

        if (result.isDocumentInitial) {
          this.fileToastService.dispatchFileDestroyed({
            [InternalNotificationKey.FILE_ID]: result.sourceFile.id,
            [InternalNotificationKey.FILE_SUBJECT]: result.sourceFile.subject,
          });
          this.router.navigateByUrl(createAbsoluteRoute(
            ApplicationRoute.DOCUMENTS,
            DocumentsRoute.DOCUMENT,
            documents[0].id,
          ));
        }
        else {
          this.announceActionCompleted(DocumentToolbarAction.DOCUMENT_MOVED_TO_ANOTHER_FILE);
          fileDetailService?.reloadObject({
            [FileDetailCountType.CONTENT]: decrement(),
          });
        }
      }
    });
  }

  onResolutionClick(documentData: DocumentDto[]) {
    let titleTemplate = 'Vyřízení dokumentu';
    if (documentData.length === 1) {
      if (documentData[0].refNumber) titleTemplate = 'Vyřízení dokumentu {{refNumber}}';
    } else {
      titleTemplate = 'Vyřízení dokumentů';
    }
    this.modalService.openComponentInModal({
      component: DocumentSettleDialogComponent,
      modalOptions: {
        width: 1000,
        height: 825,
        titleTemplate,
        titleTemplateContext: {refNumber: documentData[0].refNumber || ''},
        disableAutoMargin: true,
      },
      data: documentData,
    }).subscribe(_ => {
      this.announceActionCompleted(DocumentToolbarAction.DOCUMENT_SETTLE_STARTED);
    });
  }

  onWithdrawResolutionClick(documentData: DocumentDto[]) {
    this.apiSettlementService.settlementFindSettlementsByIds({
      body: {ids: documentData.map(d => d.settlementId!)},
    }).subscribe(settlementInfos => {
      if (settlementInfos.find(s => s.method === SettlementMethod.BY_FILE)) {
        const documentsSettledByFile = documentData.filter(d => settlementInfos.find(s => s.id === d.settlementId && s.method === SettlementMethod.BY_FILE));
        this.handleWithdrawByFile(documentsSettledByFile);
      }
      if (settlementInfos.find(s => s.method === SettlementMethod.INDIVIDUAL)) {
        const documentsSettledIndividual = documentData.filter(d => settlementInfos.find(s => s.id === d.settlementId && s.method === SettlementMethod.INDIVIDUAL));
        this.handleIndividualWithdraw(documentsSettledIndividual, settlementInfos);
      }
    });
  }

  handleIndividualWithdraw(documentData: DocumentDto[], settlementInfo: SettlementDto[]) {
    const isBulk = documentData.length > 1;
    if (documentData.find(d => d.fileId)) {
      const searchParams: SearchParams = {
        filter: [
          {
            fieldName: 'id',
            operator: FilterOperator.inSet,
            value: String(documentData.map(d => d.fileId).filter(fId => Boolean(fId))),
          },
        ],
        sort: [],
        page: 0,
        size: 1000,
      };

      this.documentSearchService.findFilesAll(searchParams).subscribe(searchRes => {
        const fileDtos = searchRes.content;
        if (fileDtos?.find(f => f.fileState === FileState.SETTLED)) {
          this.dialogService.openQuestionDialog(
            {
              title: 'Zrušení vyřízení dokumentu',
              question: isBulk ? 'Některé dokumenty jsou ve vyřízených spisech. Chcete zrušit vyřizení dokumentů včetně vyřizení celých spisů?' :
                'Dokument je ve vyřízeném spise. Chcete zrušit vyřizení dokumentu včetně vyřizení celého spisu?',
              description: '',
              leftButtonTitle: 'Zrušit vyřízení',
            }
          ).subscribe(() => {
            this.globalLoading.doLoading(
              this.apiFileService.fileBulkWithdrawSettle({body: fileDtos.map(f => f.id)})
            ).subscribe({
              next: _ => {
                this.apiDocumentService.documentBulkWithdrawSettle({body: documentData.map(d => d.id)}).subscribe({
                  next: _ => {
                    this.fileToastService.dispatchBulkOperationStartedToast(FileToastType.FILE_WITHDRAW_SETTLEMENT, {
                      [InternalNotificationKey.COUNT]: documentData.length,
                    });
                  },
                  error: error => {
                    this.fileToastService.dispatchFileErrorToast(
                      FileToastType.FILE_WITHDRAW_SETTLEMENT_ERROR,
                      esslErrorDtoToToastParameters(this.translateService, error.error),
                    );
                  }
                });
              },
              error: error => {
                this.fileToastService.dispatchFileErrorToast(
                  FileToastType.FILE_WITHDRAW_SETTLEMENT_ERROR,
                  esslErrorDtoToToastParameters(this.translateService, error.error),
                );
              }
            });
          });
        }
        else {
          this.handleSettlementWithdraw(documentData, settlementInfo);
        }
      });
    } else {
      this.handleSettlementWithdraw(documentData, settlementInfo);
    }
  }

  handleSettlementWithdraw(documentData: DocumentDto[], settlementInfo: SettlementDto[]) {
    if (settlementInfo.find(s => s.relatedDocumentId && s.type === SettlementType.BY_DOCUMENT)) {
        this.dialogService.openQuestionDialog(
          {
            title: 'Zrušení vyřízení dokumentu',
            question: 'Některé dokumenty jsou vyřízeny spolu s jinými dokumenty. Chcete pokračovat ve zrušení vyřízení obou dokumentů?',
            description: '',
            leftButtonTitle: 'Zrušit vyřízení dokumentu',
          }
        ).subscribe(() => {
          this.withdrawDocumentSettlement(documentData);
        });
    }
    else {
      this.dialogService.openQuestionDialog(
        {
          title: 'Zrušení vyřízení dokumentu',
          question: 'Chcete zrušit vyřízení dokumentu?',
          description: '',
          leftButtonTitle: 'Zrušit vyřízení dokumentu',
        }
      ).subscribe(() => {
        this.withdrawDocumentSettlement(documentData);
      });
    }
  }

  handleWithdrawByFile(documentData: DocumentDto[]) {
    const isDocumentBulk = documentData.length > 1;

    const searchParams: SearchParams = {
      filter: [
        {
          fieldName: 'id',
          operator: FilterOperator.inSet,
          value: String(documentData.map(d => d.fileId).filter(fId => Boolean(fId))),
        },
      ],
      sort: [],
      page: 0,
      size: 1000,
    };

    this.documentSearchService.findFilesAll(searchParams).subscribe(res => {
      // Question dialog only emits if an action is to be done
      this.dialogService.openQuestionDialog(
        {
          title: 'Zrušení vyřízení dokumentu',
          question:  isDocumentBulk ? 'Některé dokumenty jsou vyřízeny spisem. Chcete zrušit vyřízení celých spisů?' : 'Dokument je vyřízen spisem. Chcete zrušit vyřízení celého spisu?',
          description: '',
          leftButtonTitle: 'Zrušit vyřízení spisu',
        }
      ).subscribe(() => {
        const fileDtos = res.content!;
        const isFileBulk = fileDtos.length > 1;
        if (fileDtos?.find(f => f.fileState === FileState.SETTLED)) {
          this.dialogService.openSimpleDialog({
            title: 'Zrušení vyřízení spisu',
            leftButtonTitle: 'Zrušit vyřízení',
            content: [
              {
                text: isFileBulk ? 'Spisy budou přesunuty do složky Vyřizované referenta, který je zpracovatelem spisu.' :
                  'Spis bude přesunut do složky Vyřizované referenta, který je zpracovatelem spisu.'
              }
            ]
          }).subscribe(result => {
            if (result) {
              this.globalLoading.doLoading(this.apiFileService.fileBulkWithdrawSettle({body: fileDtos.map(f => f.id)})).subscribe({
                next: _ => {
                  if (fileDtos.length === 1) {
                    this.fileToastService.dispatchFileInfoToast(FileToastType.FILE_WITHDRAW_SETTLEMENT_STARTED,  {
                      [InternalNotificationKey.FILE_ID]: fileDtos[0].id,
                      [InternalNotificationKey.FILE_SUBJECT]: fileDtos[0].subject,
                    });
                  } else {
                    this.fileToastService.dispatchBulkOperationStartedToast(FileToastType.BULK_FILE_WITHDRAW_SETTLEMENT_STARTED,  {
                      [InternalNotificationKey.COUNT]: fileDtos.length,
                    });
                  }
                },
                error: error => {
                  this.fileToastService.dispatchFileErrorToast(
                    FileToastType.FILE_WITHDRAW_SETTLEMENT_ERROR,
                    esslErrorDtoToToastParameters(this.translateService, error.error),
                  );
                }
              });
            }
          });
        }
      });
    });
  }

  runDocumentOperation(selection: DocumentDto[], operation: DocumentOperation) {
    if (operation === DocumentOperation.ADD_TO_FILE) {
      this.onAddToFileClick(selection);
    } else if (operation === DocumentOperation.REGISTER_DOCUMENT) {
      this.onRegisterClick(selection);
    } else if (operation === DocumentOperation.SETTLE_DOCUMENT) {
      this.onResolutionClick(selection);
    } else if (operation === DocumentOperation.WITHDRAW_SETTLE_DOCUMENT) {
      this.onWithdrawResolutionClick(selection);
    }
  }

  onDocumentOperationClick(
    selectionData: DocumentDto[],
    operation: DocumentOperation,
    dialogWarningLabel: string,
    validators?: OperationValidator<DocumentDto>[],
    authorizedOperations?: GeneralAuthorizedOperation[]
  ) {
    this.bulkOperationValidationService.validateEsslObjects<DocumentDto>(
      {
        dialogWarningLabel,
        dialogWarningLabelContext: {},
        operationValidators: validators ?? [],
        esslObjects: selectionData.map(dd => this.esslObjectToValidationObject(dd)),
        authorizedOperations,
      },
      this,
    ).subscribe(validatedObjects => {
      if (validatedObjects && validatedObjects.length > 0) {
        this.runDocumentOperation(validatedObjects as DocumentDto[], operation);
      }
    });
  }

  onShareClick(data: DocumentDto) {
    this.router.navigateByUrl(createAbsoluteRoute(ApplicationRoute.DOCUMENTS, DocumentsRoute.DOCUMENT, data.id, DocumentDetailRoute.SHARE));
  }

  esslObjectToValidationObject(object: DocumentDto) {
    return {
      entityId: object.id,
      authorizedEntityType: isDocumentEntity(object.entityType) ? AuthorizedEntityType.DOCUMENT : AuthorizedEntityType.FILE,
      entityIcon: getObjectIcon(object.objectClass as unknown as ObjectClass)!,
      entityData: object,
      entityName: getObjectLabel(this.translateService, object),
    };
  }

  onCirculationOperationClick(
    titleTemplate: string,
    titleTemplateBulk: string,
    modalComponent: Type<AbstractHandoverFormModalComponent>,
    circulationActivityType: CirculationActivityType,
    dialogWarningLabel: string,
    selectionData: DocumentDto[],
    validators?: OperationValidator<DocumentDto>[],
    authorizedOperations?: GeneralAuthorizedOperation[]
  ) {
    this.bulkOperationValidationService.validateEsslObjects<DocumentDto>(
      {
        dialogWarningLabel,
        dialogWarningLabelContext: {},
        operationValidators: validators ?? [],
        esslObjects: selectionData.map(dd => this.esslObjectToValidationObject(dd)),
        authorizedOperations,
      },
      this,
    ).subscribe(validatedObjects => {
      if (validatedObjects && validatedObjects.length > 0) {
        this.openCirculationModal(
          titleTemplate,
          titleTemplateBulk,
          modalComponent,
          validatedObjects,
        ).subscribe(result => {
          if (result) {
            if (validatedObjects.length === 1) {
              this.dispatchTaskAssignedToast(validatedObjects[0] as DocumentDto, circulationActivityType, result);
              this.mainMenuCountsService.updateMainMenuCounters([CounterTypeGroup.DOCUMENT_FILE_TASKS_RECEIVED_CONSIGNMENTS_TRANSFERS_COUNTS]);
            }
            else {
              this.dispatchBulkTaskAssignedToast(validatedObjects as DocumentDto[], circulationActivityType);
            }
          }
        });
      }
    });
  }

  onRegisterClick(documentData: DocumentDto[]) {
    if (documentData.length > 1) {
      this.globalLoading.doLoading(
        this.apiDocumentService.documentBulkRegister({
          body: documentData.map(d => ({documentId: d.id}))
        })
      ).subscribe({
        next: _ => {
          this.documentToastService.dispatchBulkOperationStartedToast(DocumentToastType.DOCUMENT_BULK_REGISTER_STARTED,{
            [InternalNotificationKey.COUNT]: documentData.length
          });
        },
        error: _ => {
          this.documentToastService.dispatchSimpleErrorToast(DocumentToastType.DOCUMENT_BULK_ACTION_ERROR);
        }
      });
    } else {
      this.globalLoading.doLoading(
        this.apiDocumentService.documentRegister({
          id: documentData[0].id,
          body: {}
        })
      ).subscribe(_ => {
        this.documentToastService.dispatchDocumentInfoToast(DocumentToastType.DOCUMENT_REGISTERED, {
          [InternalNotificationKey.DOCUMENT_ID]: documentData[0].id,
          [InternalNotificationKey.DOCUMENT_SUBJECT]: documentData[0].subject,
        });

        this.announceActionCompleted(DocumentToolbarAction.DOCUMENT_REGISTERED);
      });
    }
  }

  onReregisterClick(documentData: DocumentDto[], authorizedOperations?: GeneralAuthorizedOperation[]) {
    // find first document in selection that has filingRegisterId
    const registeredDocument = documentData.find(doc => !isNil(doc.filingRegisterId));
    const validators = [
      DocumentOperationValidators.selectedItemIsNotDocument(),
      DocumentOperationValidators.documentIsNotRegistered(),
      CommonToolbarValidators.isEsslObjectLocked(),
    ];
    if (registeredDocument) {
      // This will ensure that bulk reregistration is done on the documents that belongs to same register.
      // This must be done because ReregisterDialogComponent checks if there is a filing register to move to and if selection
      // constins documents from heterogenous filing registers than it will not work properly
      validators.push(DocumentOperationValidators.documentIsInDifferentFilingRegister(registeredDocument.filingRegisterId!));
    }

    this.bulkOperationValidationService.validateEsslObjects<DocumentDto>(
      {
        dialogWarningLabel: 'Některé dokumenty ({{errorCount}}) není možné přeevidovat. Hromadná akce přeevidování bude provedena jen s vyhovujícími dokumenty ({{successCount}}).',
        dialogWarningLabelContext: {
          count: String(documentData.length)
        },
        operationValidators: validators,
        esslObjects: documentData.map(dd => this.esslObjectToValidationObject(dd)),
        authorizedOperations,
      },
      this,
    ).subscribe(validatedObjects => {
      if (validatedObjects && validatedObjects.length > 0) {
        this.modalService.openComponentInModal<RegisterDocumentInFilingRegisterResponseDto, DocumentDto[]>({
          component: ReregisterDialogComponent,
          modalOptions: {
            width: 550,
            height: 450,
            titleTemplate: validatedObjects.length > 1 ? 'Přeevidování dokumentů ({{count}})' : 'Přeevidování dokumentu - {{refNumber}}',
            titleTemplateContext: {
              count: String(validatedObjects.length),
              refNumber: validatedObjects[0].refNumber ?? WITHOUT_REF_NUMBER,
            }
          },
          data: documentData,
        }).subscribe(result => {
          if (result) {
            if (validatedObjects.length === 1) {
              this.documentToastService.dispatchDocumentInfoToast(DocumentToastType.DOCUMENT_REREGISTERED, {
                [InternalNotificationKey.DOCUMENT_ID]: validatedObjects[0].id,
                [InternalNotificationKey.DOCUMENT_SUBJECT]: validatedObjects[0].subject,
              });
            }

            this.announceActionCompleted(DocumentToolbarAction.DOCUMENT_REREGISTERED);
          }
        });
      }
    });
  }

  onPrintDocumentInfo(documentData: DocumentDto[]) {
    this.bulkOperationValidationService.validateEsslObjects<DocumentDto>(
      {
        dialogWarningLabel: 'Pro některé objekty ({{errorCount}}) není možno provést tisk jejich informací. Hromadný tisk informací bude proveden jen s vyhovujícími dokumenty ({{successCount}}).',
        dialogWarningLabelContext: {},
        operationValidators: [
          DocumentOperationValidators.selectedItemIsNotDocument(),
        ],
        esslObjects: documentData.map(dd => this.esslObjectToValidationObject(dd)),
        authorizedOperations: [],
      },
      this,
    ).subscribe(validatedObjects => {
      if (validatedObjects && validatedObjects.length > 0) {
        let dialogTitle: string;
        let dialogTitleContext: Record<string, string>;

        if (validatedObjects.length === 1) {
          dialogTitle = 'Tisk informací o dokumentu';
          dialogTitleContext = {};
        }
        else {
          dialogTitle = 'Tisk informací o dokumentech ({{count}})';
          dialogTitleContext = { count: String(validatedObjects.length) };
        }

        this.modalService.openComponentInModal<boolean, DocumentDto[]>({
          component: PrintDocumentInfoDialogComponent,
          modalOptions: {
            width: 400,
            height: 500,
            titleTemplate: dialogTitle,
            titleTemplateContext: dialogTitleContext,
          },
          data: validatedObjects as DocumentDto[],
        }).subscribe(dialogResult => {
          if (dialogResult) {
            this.documentToastService.dispatchSimpleInfoToast(DocumentToastType.DOCUMENT_PRINT_INFO_CREATED);
          }
        });
      }
    });
  }

  private withdrawDocumentSettlement(documentData: DocumentDto[]) {
    this.apiDocumentService.documentBulkWithdrawSettle({body: documentData.map((d => d.id))}).subscribe({
      next: _ => {
        if (documentData.length > 1) {
          this.documentToastService.dispatchBulkOperationStartedToast(DocumentToastType.DOCUMENT_BULK_WITHDRAW_SETTLEMENT_STARTED, {
            [InternalNotificationKey.COUNT]: documentData.length,
          });
        }
      },
      error: error => {
        this.documentToastService.dispatchWithdrawSettlementError(
          esslErrorDtoToToastParameters(this.translateService, error.error),
        );
      },
    });
  }

  private openCirculationModal(
    titleTemplate: string,
    titleTemplateBulk: string,
    modalComponent: Type<AbstractHandoverFormModalComponent>,
    circulationObjects: Nullable<DocumentDto | FileDto>[]
  ) {
    return openGeneralCirculationModal(
      // @ts-ignore -- circumvent private property error
      this,
      titleTemplate,
      titleTemplateBulk,
      modalComponent,
      circulationObjects,
      DocumentToolbarAction.DOCUMENT_CIRCULATION_STARTED,
    );
  }

  private dispatchTaskAssignedToast(documentData: DocumentDto, activityType: CirculationActivityType, activityId: number) {
    const templateData = {
      [InternalNotificationKey.ACTIVITY_TYPE_KEY]: `en.circulationActivityType.${activityType}`,
      [InternalNotificationKey.DOCUMENT_ID]: String(documentData.id),
      [InternalNotificationKey.DOCUMENT_SUBJECT]: documentData.subject,
      [InternalNotificationKey.ACTIVITY_ID]: activityId,
      [InternalNotificationKey.REF_NUMBER]: documentData.refNumber ?? this.translateService.instant(WITHOUT_REF_NUMBER),
      [InternalNotificationKey.ENTITY_TYPE_CLASS]: entityTypeToEntityTypeClass(EntityType.DOCUMENT),
    };

    if (
      activityType === CirculationActivityType.DOCUMENT_HANDOVER ||
      activityType === CirculationActivityType.FILE_HANDOVER
    ) {
      this.circulationToastService.dispatchHandoverTaskAssignedInitiator(templateData);
    }
    else {
      this.circulationToastService.dispatchTaskAssignedInitiator(templateData);
    }
  }

  private dispatchBulkTaskAssignedToast(documentData: DocumentDto[], activityType: CirculationActivityType) {
    const templateData = {
      [InternalNotificationKey.ACTIVITY_TYPE_KEY]: `en.circulationActivityType.${activityType}`,
      [InternalNotificationKey.COUNT]: String(documentData.length),
    };

    if (
      activityType === CirculationActivityType.DOCUMENT_HANDOVER ||
      activityType === CirculationActivityType.FILE_HANDOVER
    ) {
      this.circulationToastService.dispatchBulkHandoverTaskToast(templateData);
    }
    else {
      this.circulationToastService.dispatchBulkTaskAssignedInitiator(templateData);
    }
  }

  private onReativationClick(documentData: DocumentDto){
    this.globalLoading.doLoading(
      this.apiDocumentService.documentReactivateDocument({id: documentData.id!})
    ).subscribe({
      next: _ => {
        this.documentToastService.dispatchDocumentInfoToast(DocumentToastType.DOCUMENT_REACTIVATED,{
          [InternalNotificationKey.DOCUMENT_ID]: documentData.id,
          [InternalNotificationKey.DOCUMENT_SUBJECT]: documentData.subject,
        });
        this.mainMenuCountsService.updateMainMenuCounters([CounterTypeGroup.DOCUMENT_FILE_TASKS_RECEIVED_CONSIGNMENTS_TRANSFERS_COUNTS]);
        this.announceActionCompleted(DocumentToolbarAction.DOCUMENT_REACTIVATED);
      }
    });
  }

  private onHandoverToExternalAppClick(selectionData: DocumentDto[], validators?: OperationValidator<DocumentDto>[], authorizedOperations?: GeneralAuthorizedOperation[]) {
    this.bulkOperationValidationService.validateEsslObjects<DocumentDto>(
      {
        dialogWarningLabel: 'Některé objekty ({{errorCount}}) není možno předat externí aplikaci. Hromadná akce předání externí aplikaci bude provedena jen s vyhovujícími objekty ({{successCount}}).',
        dialogWarningLabelContext: {},
        operationValidators: validators ?? [],
        esslObjects: selectionData.map(dd => this.esslObjectToValidationObject(dd)),
        authorizedOperations,
      },
      this,
    ).subscribe(validatedObjects => {
      if (validatedObjects && validatedObjects.length > 0) {
        let dialogTitle = 'Předání externí aplikaci';
        let dialogTitleParams = {};
        if (validatedObjects.length === 1) {
          if (isFileObject(validatedObjects[0])) {
            dialogTitle = 'Předání spisu - {{refNumber}}';
          }
          else if (isDocumentObject(validatedObjects[0])) {
            dialogTitle = 'Předání dokumentu - {{refNumber}}';
          }
          dialogTitleParams = {
            refNumber: (isNil((validatedObjects[0] as DocumentDto | FileDto).refNumber) ? this.translateService.instant('bez čísla jednacího') : (validatedObjects[0] as DocumentDto | FileDto).refNumber)
          };
        }
        else {
          dialogTitle = 'Hromadné předání externí aplikaci ({{count}})';
          dialogTitleParams = {
            count: validatedObjects.length
          };
        }

        const handoverEntities: HandoverEntity[] = [];
        validatedObjects.forEach(entity => {
          if (isFileObject(entity)) {
            const file = entity as FileDto;
            handoverEntities.push({
              entityId: file.id,
              entityType: file.entityType!,
              fileTypeId: file.fileTypeId,
            });
          } else if (isDocumentObject(entity)) {
            const document = entity as DocumentDto;
            handoverEntities.push({
              entityId: document.id,
              entityType: document.entityType!,
              documentTypeId: document.documentTypeId,
            });
          }
        });

        this.modalService.openComponentInModal<boolean, HandoverToExternalAppDialogData>({
          component: HandoverToExternalAppDialogComponent,
          modalOptions: {
            width: 800,
            height: 600,
            titleTemplate: dialogTitle,
            titleTemplateContext: dialogTitleParams
          },
          data: {
            handoverEntities
          },
        }).subscribe( result => {
          if (result) {
            if (handoverEntities.length === 1) {
              this.circulationToastService.dispatchHandoverTaskToExternalAppStarted({
                [InternalNotificationKey.ENTITY_TYPE]: String(validatedObjects[0].entityType),
                [InternalNotificationKey.REF_NUMBER]: String((validatedObjects[0] as DocumentDto | FileDto).refNumber),
                [InternalNotificationKey.ID]: String(validatedObjects[0].id),
                [InternalNotificationKey.ACTIVITY_TYPE_KEY]: this.translateService.instant(
                  'en.circulationActivityType.' + (isFileObject(validatedObjects[0]) ? CirculationActivityType.FILE_ISSD_HANDOVER : CirculationActivityType.DOCUMENT_ISSD_HANDOVER)),
                [InternalNotificationKey.SUBJECT]: String((validatedObjects[0] as DocumentDto | FileDto).subject)
              });
            }
            else {
              this.circulationToastService.dispatchBulkHandoverTaskToExternalAppStarted({
                [InternalNotificationKey.COUNT]: String(validatedObjects.length),
              });
            }
            this.mainMenuCountsService.updateMainMenuCounters([CounterTypeGroup.DOCUMENT_FILE_TASKS_RECEIVED_CONSIGNMENTS_TRANSFERS_COUNTS]);
            this.announceActionCompleted(DocumentToolbarAction.DOCUMENT_HANDED_OVER_TO_EXTERNAL_APP);
          }
        });
      }
    });
  }

}
