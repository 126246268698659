import {Component, inject, Input, OnInit} from '@angular/core';
import {ApiDocumentService, ReceivedDocumentDto} from '|api/document';
import {DistributionClassDto} from '|api/codebook';
import {ApiReceivedPaperConsignmentService, ReceivedPaperConsignmentDto} from '|api/sad';

import {combineLatest, Observable} from 'rxjs';
import {DeliveryTypeOption, deliveryTypesToOption} from '../../../core/services/data-mapping.utils';
import {IczFormArray, IczFormGroup, locateOptionByValue} from '@icz/angular-form-elements';
import {LoadingIndicatorService} from '@icz/angular-essentials';
import {ReceivedDocumentUsage} from '../received-consignments.model';
import {
  ConfigPropValueService,
  DocumentDetailService,
  HistoryService,
  PrintingOperationType,
  PrintService
} from '../../../services';
import {
  CodebookService,
  CounterTypeGroup,
  createAbsoluteRoute,
  DocumentToastService,
  DocumentToastType,
  LabelTemplatesConfigurationKeys,
  MainMenuCountsService
} from '../../../core';
import {ApplicationRoute} from '../../../enums/shared-routes.enum';
import {consignmentFormToRegisterContentDto} from '../received-document-form/received-document-model';
import {DocumentDetailRoute, DocumentsRoute} from '../../../enums/documents-routes.enum';
import {Router} from '@angular/router';
import {InternalNotificationKey} from '|api/notification';
import {TranslateService} from '@ngx-translate/core';
import {switchMap} from 'rxjs/operators';
import {esslErrorDtoToToastParameters} from '../../notifications/toast.service';

@Component({
  selector: 'icz-document-register-content',
  templateUrl: './document-register-content.component.html',
  styleUrls: ['./document-register-content.component.scss'],
  providers: [DocumentDetailService]
})
export class DocumentRegisterContentComponent implements OnInit  {

  protected loadingIndicatorService = inject(LoadingIndicatorService);
  private historyService = inject(HistoryService);
  private codebookService = inject(CodebookService);
  private printService = inject(PrintService);
  private apiPrintLabelService = inject(ApiDocumentService);
  private apiReceivedPaperConsignmentService = inject(ApiReceivedPaperConsignmentService);
  private router = inject(Router);
  private mainMenuCountsService = inject(MainMenuCountsService);
  private documentToastService = inject(DocumentToastService);
  private translateService = inject(TranslateService);
  private configPropValueService = inject(ConfigPropValueService);

  @Input({required: true}) receivedDocumentUsage!: ReceivedDocumentUsage;
  @Input({required: true}) unopenedDocument!: ReceivedDocumentDto;
  @Input({required: true}) form!: IczFormGroup;

  formGroupsToCheck!: string[];

  createdDocumentId!: number;

  unopenedConsignment: Nullable<ReceivedPaperConsignmentDto>;
  selectedDistributionClass: Nullable<DistributionClassDto>;
  deliveryOptions!: DeliveryTypeOption[];

  ngOnInit() {
    this.loadingIndicatorService.doLoading(
      combineLatest([
        this.apiReceivedPaperConsignmentService
          .receivedPaperConsignmentFindConsignmentsForDocument({
            id: this.unopenedDocument!.id,
            page: 0,
          }),
        this.codebookService.deliveryTypes().pipe(deliveryTypesToOption)
      ]), this).subscribe(([page, dt]) => {
      this.deliveryOptions = dt;

      if (page?.content?.[0]) {
        this.unopenedConsignment = page.content[0];
        this.form.patchValue(this.unopenedConsignment);

        if (this.unopenedConsignment.deliveryTypeId) {
          this.selectDistributionClass(this.unopenedConsignment.deliveryTypeId);
        }
        if (this.isRegisterContentFilingOffice) {
          this.firstDocumentWithHandoverForm.patchValue(this.unopenedDocument!);
        } else if (this.isRegisterContentOfficer) {
          this.firstDocumentNoHandoverForm.patchValue(this.unopenedDocument!);
        }
      }
    });
  }

  selectDistributionClass(id: number) {
    const selectedOption = locateOptionByValue(this.deliveryOptions, id);
    if (selectedOption) {
      this.selectedDistributionClass = selectedOption.data!.distributionClass as DistributionClassDto;
    }
  }

  get isRegisterContentFilingOffice() {
    return this.receivedDocumentUsage === ReceivedDocumentUsage.REGISTER_CONTENT_FILING_OFFICE;
  }

  get isRegisterContentOfficer() {
    return this.receivedDocumentUsage === ReceivedDocumentUsage.REGISTER_CONTENT_OFFICER;
  }

  get receivedDocumentCreateDtoListNoHandoverControl(): IczFormArray {
    return this.form?.get('receivedDocumentCreateDtoListNoHandover') as IczFormArray;
  }

  get receivedDocumentCreateDtoListWithHandoverControl(): IczFormArray {
    return this.form?.get('receivedDocumentCreateDtoListWithHandover') as IczFormArray;
  }

  get firstDocumentNoHandoverForm(): IczFormGroup {
    return this.receivedDocumentCreateDtoListNoHandoverControl?.controls[0] as IczFormGroup;
  }

  get firstDocumentWithHandoverForm(): IczFormGroup {
    return this.receivedDocumentCreateDtoListWithHandoverControl?.controls[0] as IczFormGroup;
  }
  blockingAction = false;

  get submitDisabled() {
    return this.loadingIndicatorService.isLoading(this) || this.blockingAction;
  }

  printLabel() {
    this.configPropValueService.getPropValuesForCurrentOrganization(
      [LabelTemplatesConfigurationKeys.PRINT_COMPONENT]
    ).pipe(
      switchMap(propertyValues => {
        const printComponents = propertyValues[LabelTemplatesConfigurationKeys.PRINT_COMPONENT].value as boolean;

        return this.apiPrintLabelService.documentPrintLabelBatch({body: {
          documentIds: [this.createdDocumentId],
          printComponents,
        }});
      }),
    ).subscribe(labels => {
      this.printService.printText(PrintingOperationType.LABEL, labels).subscribe();
    });
  }

  onSubmit() {
    const consignment = consignmentFormToRegisterContentDto(this.form!.getRawValue(), this.receivedDocumentUsage);

    // todo(mh) ESSL-11009 check that externalRetentionTriggerYear is sent in form
    let req$: Observable<ReceivedPaperConsignmentDto>;

    if (this.receivedDocumentUsage === ReceivedDocumentUsage.REGISTER_CONTENT_OFFICER) {
      req$ = this.apiReceivedPaperConsignmentService.receivedPaperConsignmentRegisterContentByOfficer({id: this.unopenedConsignment!.id!, body: consignment});
    }
    else if (this.receivedDocumentUsage === ReceivedDocumentUsage.REGISTER_CONTENT_FILING_OFFICE) {
      req$ = this.apiReceivedPaperConsignmentService.receivedPaperConsignmentRegisterContentByFilingOffice({id: this.unopenedConsignment!.id!, body: consignment});
    }
    this.loadingIndicatorService.doLoading(req$!, this).subscribe({
      next: consignment => {
        this.documentToastService.dispatchDocumentInfoToast(DocumentToastType.DOCUMENT_CONTENT_REGISTERED, {
          [InternalNotificationKey.DOCUMENT_ID]: consignment.receivedDocuments![0]!.id,
          [InternalNotificationKey.DOCUMENT_SUBJECT]: consignment.receivedDocuments![0].subject,
        });
        this.mainMenuCountsService.updateMainMenuCounters([CounterTypeGroup.DOCUMENT_FILE_TASKS_RECEIVED_CONSIGNMENTS_TRANSFERS_COUNTS]);
        this.form.markAsPristine();
        this.router.navigateByUrl(createAbsoluteRoute(ApplicationRoute.DOCUMENTS, DocumentsRoute.DOCUMENT, consignment.receivedDocuments![0]!.id, DocumentDetailRoute.PREVIEW));
      },
      error: error => {
        this.documentToastService.dispatchDocumentErrorToast(
          DocumentToastType.DOCUMENT_CONTENT_REGISTERED_ERROR,
          esslErrorDtoToToastParameters(this.translateService, error.error),
        );
      }
    });
  }

  onCancel() {
    this.historyService.routeBack(createAbsoluteRoute(
      ApplicationRoute.DOCUMENTS,
    ));
  }
}
