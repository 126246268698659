import {ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit} from '@angular/core';
import {LoadingIndicatorService} from '@icz/angular-essentials';
import {injectModalData, injectModalRef} from '@icz/angular-modal';
import {DocumentView} from '../../../services/toolbar-common.utils';
import {
  SettledDocumentsDataSource
} from '../../../../../../../../../../apps/espis/src/app/modules/documents/modules/settled-documents/settled-documents.datasource';
import {DocumentSearchService} from '../../../../../../services/document-search.service';
import {FilterItemTree, NonemptyFilterItem, SearchParams, TableComponent} from '@icz/angular-table';
import {ConfigPropValueService} from '../../../../../../services/config-prop-value.service';
import {StorageUnitsConfigKeys} from '../../../../../../core/services/config/config-keys.enum';
import {ObjectClass} from '|api/commons';
import {TranslateService} from '@ngx-translate/core';
import {getUnitViewSelectorOptions} from '../../../../abstract-unit-view-component/abstract-unit-view-component';
import {CodebookService} from '../../../../../../core/services/codebook.service';
import {DocumentsTableColumn} from '../../../../document-table/documents-table.columnsets';

export interface StorageUnitFillerSelectionDialogData {
  isUnitView: boolean;
  activeFilters?: Nullable<FilterItemTree>;
}

export interface StorageUnitFillerSelectionDialogResult {
  searchParams: SearchParams;
  activeFilters: FilterItemTree;
  orgUnitId: Nullable<number>;
}

@Component({
  selector: 'icz-storage-unit-filler-selection-dialog',
  templateUrl: './storage-unit-filler-selection-dialog.component.html',
  styleUrls: ['./storage-unit-filler-selection-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StorageUnitFillerSelectionDialogComponent implements OnInit {

  protected loadingService = inject(LoadingIndicatorService);
  protected documentSearchService = inject(DocumentSearchService);
  protected modalRef = injectModalRef<StorageUnitFillerSelectionDialogResult>();
  protected configPropValueService = inject(ConfigPropValueService);
  protected changeDetectorRef = inject(ChangeDetectorRef);
  protected translateService = inject(TranslateService);
  protected codebookService = inject(CodebookService);
  protected modalData = injectModalData<StorageUnitFillerSelectionDialogData>();

  protected selectorOptions$ = getUnitViewSelectorOptions(this.codebookService);

  currentSearchParams!: SearchParams;
  activeFilters!: FilterItemTree;
  objectClasses: ObjectClass[] = []; // default is empty objectClasses, which means no restriction
  activeSelectorValue: Nullable<number> = null;

  dataSource!: SettledDocumentsDataSource;

  getDataSource() {
    return new SettledDocumentsDataSource(
      this.documentSearchService,
      {
        isUnitView: this.modalData.isUnitView,
        orgUnitId: this.activeSelectorValue,
      },
      this.objectClasses,
      false,
      false
    );
  }

  ngOnInit() {
    this.configPropValueService.getPropValuesForCurrentOrganization([StorageUnitsConfigKeys.STORE_DOCUMENTS_SEPARATELY_UNTIL]).subscribe(vals => {
      const toDate = new Date(vals[StorageUnitsConfigKeys.STORE_DOCUMENTS_SEPARATELY_UNTIL].value! as string);
      const now = new Date();

      const allowSeparateDocumentStoring = toDate > now;
      if (!allowSeparateDocumentStoring) {
        this.objectClasses = [ObjectClass.FILE, ObjectClass.FILE_DIGITAL];
      }

      this.dataSource = this.getDataSource();
      this.changeDetectorRef.detectChanges();
    });
  }

  selectorValueChanged(value: Nullable<number | number[]>) {
    this.activeSelectorValue = value as Nullable<number>;
    this.dataSource = this.getDataSource();
  }

  submit() {
    this.modalRef.close({
      searchParams: this.currentSearchParams,
      activeFilters: this.activeFilters,
      orgUnitId: this.activeSelectorValue,
    });
  }

  cancel() {
    this.modalRef.close();
  }

  searchParamsSelected(searchParams: SearchParams) {
    this.currentSearchParams = searchParams;
  }

  activeFiltersChanged(activeFilters: FilterItemTree) {
    this.activeFilters = activeFilters;
  }

  docTableInitialized(table: TableComponent<DocumentsTableColumn>) {
    if (this.modalData.activeFilters) {
      if (table?.tableToolbarService) {
        this.modalData.activeFilters?.values.forEach(filterItem => {
          table.tableToolbarService.addFilterItem(filterItem as NonemptyFilterItem, true);
        });
        this.changeDetectorRef.detectChanges();
      }
    }
  }

  protected readonly DocumentView = DocumentView;
}
