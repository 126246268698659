import {DestroyRef, inject, Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, debounceTime, filter, tap} from 'rxjs/operators';

import {SystemNotificationsService, SystemNotificationType} from './system-notifications.service';
import {
  AgendaTransferConfigKeys,
  CirculationConfigKey,
  DocumentDeliveryConfigurationKeys,
  EnvironmentConfigKeys,
  GeneratorsConfigKey,
  MiscConfigKey,
  SignaturesConfigurationKeys,
  StorageUnitsConfigKeys
} from './config-keys.enum';
import {ConfigPropValueService} from '../../../services/config-prop-value.service';
import {castStream} from '../../../lib/rxjs';
import {EnvironmentIconColorScheme} from '../../../services/user-settings.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';


const APPLICATION_CONFIG_KEYS = [
  CirculationConfigKey.ALLOW_HAND_FOR_ACKNOWLEDGEMENT,
  CirculationConfigKey.ALLOW_HAND_FOR_STATEMENT,
  CirculationConfigKey.ALLOW_HAND_FOR_RESOLUTION,
  CirculationConfigKey.ALLOW_HAND_FOR_APPROVAL,
  CirculationConfigKey.COMPONENT_SIGNING_ENABLED,
  CirculationConfigKey.COMPONENT_APPROVAL_ENABLED,
  GeneratorsConfigKey.UID_ID_SOURCE,
  MiscConfigKey.DEFAULT_CURRENCY,
  MiscConfigKey.FE_ERROR_REPORTING_ENABLED,
  DocumentDeliveryConfigurationKeys.RECEIVED_DOCUMENT_EXTERNAL_ID_ENABLED,
  DocumentDeliveryConfigurationKeys.RECEIVED_DOCUMENT_REGISTRATION_REQUIRED,
  DocumentDeliveryConfigurationKeys.RECEIVED_DOCUMENT_DEFAULT_REGISTRATION,
  DocumentDeliveryConfigurationKeys.RECEIVED_DOCUMENT_DELIVERY_WITHOUT_FORWARDING,
  DocumentDeliveryConfigurationKeys.OWN_DOCUMENT_EXTERNAL_ID_ENABLED,
  DocumentDeliveryConfigurationKeys.OWN_DOCUMENT_REGISTRATION_REQUIRED,
  DocumentDeliveryConfigurationKeys.OWN_DOCUMENT_DEFAULT_REGISTRATION,
  DocumentDeliveryConfigurationKeys.CONSIGNMENT_EXTERNAL_ID_ENABLED,
  SignaturesConfigurationKeys.VISIBLE_SIGNATURE_ENABLED,
  SignaturesConfigurationKeys.DEFAULT_SIGNATURE_CERT_LOCATION,
  SignaturesConfigurationKeys.TIMESTAMPING_MANDATORNESS,
  EnvironmentConfigKeys.DEFAULT_ICON_COLOR_SCHEME,
  EnvironmentConfigKeys.REQUIRED_IDT_VERSION,
  StorageUnitsConfigKeys.REQUIRE_ENTITY_CLASS_CONSISTENCY,
  StorageUnitsConfigKeys.REQUIRE_DISPOSAL_YEAR_CONSISTENCY,
  StorageUnitsConfigKeys.FORCE_REQUIRED_DISPOSAL_SCHEDULE,
  StorageUnitsConfigKeys.STORE_DOCUMENTS_SEPARATELY_UNTIL,
  MiscConfigKey.FILING_OFFICE_RECEIVE_MESSAGE_TIMER,
  MiscConfigKey.ELASTIC_MAX_SEARCH_RESULT_WINDOW,
  AgendaTransferConfigKeys.AGENDA_TRANSFER_IS_ACTIVE,
];

export enum SignatureCertificateLocation {
  LOCAL = 'LOCAL',
  REMOTE = 'REMOTE',
}

export enum SignaturePlaceTimestamp {
  ALLOWED = 'ALLOWED',
  FORCED = 'FORCED',
}

export function getReceivedDocumentDefaultAssignRefNrValue(applicationConfigService: ApplicationConfigService): boolean {
  const allowRegistrationChoice = applicationConfigService.receivedDocumentRegistrationRequired;
  const defaultAssignRefNr = applicationConfigService.receivedDocumentDefaultDocumentRegistration;

  return !allowRegistrationChoice ? true : defaultAssignRefNr;
}

export function getOwnDocumentDefaultAssignRefNrValue(applicationConfigService: ApplicationConfigService): boolean {
  const allowRegistrationChoice = applicationConfigService.ownDocumentRegistrationRequired;
  const defaultAssignRefNr = applicationConfigService.ownDocumentDefaultDocumentRegistration;

  return !allowRegistrationChoice ? true : defaultAssignRefNr;
}


@Injectable({
  providedIn: 'root'
})
export class ApplicationConfigService {

  private systemNotificationsService = inject(SystemNotificationsService);
  private configPropValueService = inject(ConfigPropValueService);
  private destroyRef = inject(DestroyRef);

  allowHandForResolution!: boolean;
  allowHandForApproval!: boolean;
  allowHandForStatement!: boolean;
  allowHandForAcknowledgement!: boolean;
  allowComponentHandForSigning!: boolean;
  allowComponentHandForApproval!: boolean;
  uidIdSource!: string;
  defaultCurrency!: string;
  isErrorReportingEnabled!: boolean;
  receivedDocumentExternalIdEnabled!: boolean;
  receivedDocumentRegistrationRequired!: boolean;
  receivedDocumentDefaultDocumentRegistration!: boolean;
  receivedDocumentDeliveryWithoutForwarding!: boolean;
  ownDocumentExternalIdEnabled!: boolean;
  ownDocumentRegistrationRequired!: boolean;
  ownDocumentDefaultDocumentRegistration!: boolean;
  consignmentExternalIdEnabled!: boolean;
  isVisualSignatureEnabled!: boolean;
  defaultSignatureCertificateLocation!: SignatureCertificateLocation;
  signatureTimestampMandatorness!: SignaturePlaceTimestamp;
  defaultIconColorScheme!: EnvironmentIconColorScheme;
  defaultIdtVersion!: string;
  requireEntityClassConsistency!: boolean;
  requireDisposalYearConsistency!: boolean;
  forceRequiredDisposalSchedule!: boolean;
  storeDocumentsSeparatelyUntil!: Date;
  filingOfficeHeartbeat!: number;
  elasticMaxSearchResultWindow!: number;
  allowAgendaTransfer!: boolean;

  isHydrated = false;

  initialize() {
    this.systemNotificationsService.initialize();
    this.systemNotificationsService.notification$.pipe(
      filter(n => n.type === SystemNotificationType.SERVICE_REGISTERED && n.serviceName === 'CONFIG-SERVER'),
      debounceTime(1000),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(_ => this.loadConfig().subscribe());
  }

  loadConfig(): Observable<void> {
    const loadConfig$ = this.configPropValueService.getPropValuesForCurrentOrganization(APPLICATION_CONFIG_KEYS, true, false);
    return loadConfig$.pipe(
      takeUntilDestroyed(this.destroyRef),
      catchError(_ => of(null)),
      tap(configValues => {
        if (configValues) {
          this.isHydrated = true;

          try {
            this.allowHandForResolution = configValues[CirculationConfigKey.ALLOW_HAND_FOR_RESOLUTION].value as boolean;
            this.allowHandForApproval = configValues[CirculationConfigKey.ALLOW_HAND_FOR_APPROVAL].value as boolean;
            this.allowHandForStatement = configValues[CirculationConfigKey.ALLOW_HAND_FOR_STATEMENT].value as boolean;
            this.allowHandForAcknowledgement = configValues[CirculationConfigKey.ALLOW_HAND_FOR_ACKNOWLEDGEMENT].value as boolean;
            this.allowComponentHandForSigning = configValues[CirculationConfigKey.COMPONENT_SIGNING_ENABLED].value as boolean;
            this.allowComponentHandForApproval = configValues[CirculationConfigKey.COMPONENT_APPROVAL_ENABLED].value as boolean;
            this.uidIdSource = configValues[GeneratorsConfigKey.UID_ID_SOURCE].value as string;
            this.defaultCurrency = configValues[MiscConfigKey.DEFAULT_CURRENCY].value as string;
            this.isErrorReportingEnabled = configValues[MiscConfigKey.FE_ERROR_REPORTING_ENABLED].value as boolean;
            this.receivedDocumentExternalIdEnabled = configValues[DocumentDeliveryConfigurationKeys.RECEIVED_DOCUMENT_EXTERNAL_ID_ENABLED].value as boolean;
            this.receivedDocumentRegistrationRequired = configValues[DocumentDeliveryConfigurationKeys.RECEIVED_DOCUMENT_REGISTRATION_REQUIRED].value as boolean;
            this.receivedDocumentDefaultDocumentRegistration = configValues[DocumentDeliveryConfigurationKeys.RECEIVED_DOCUMENT_DEFAULT_REGISTRATION].value as boolean;
            this.receivedDocumentDeliveryWithoutForwarding = configValues[DocumentDeliveryConfigurationKeys.RECEIVED_DOCUMENT_DELIVERY_WITHOUT_FORWARDING].value as boolean;
            this.ownDocumentExternalIdEnabled = configValues[DocumentDeliveryConfigurationKeys.OWN_DOCUMENT_EXTERNAL_ID_ENABLED].value as boolean;
            this.ownDocumentRegistrationRequired = configValues[DocumentDeliveryConfigurationKeys.OWN_DOCUMENT_REGISTRATION_REQUIRED].value as boolean;
            this.ownDocumentDefaultDocumentRegistration = configValues[DocumentDeliveryConfigurationKeys.OWN_DOCUMENT_DEFAULT_REGISTRATION].value as boolean;
            this.consignmentExternalIdEnabled = configValues[DocumentDeliveryConfigurationKeys.CONSIGNMENT_EXTERNAL_ID_ENABLED].value as boolean;
            this.isVisualSignatureEnabled = configValues[SignaturesConfigurationKeys.VISIBLE_SIGNATURE_ENABLED].value as boolean;
            this.defaultSignatureCertificateLocation = configValues[SignaturesConfigurationKeys.DEFAULT_SIGNATURE_CERT_LOCATION].value as SignatureCertificateLocation;
            this.signatureTimestampMandatorness = configValues[SignaturesConfigurationKeys.TIMESTAMPING_MANDATORNESS].value as SignaturePlaceTimestamp;
            this.defaultIconColorScheme = configValues[EnvironmentConfigKeys.DEFAULT_ICON_COLOR_SCHEME].value as EnvironmentIconColorScheme;
            this.defaultIdtVersion = configValues[EnvironmentConfigKeys.REQUIRED_IDT_VERSION].value as string;
            this.requireEntityClassConsistency = configValues[StorageUnitsConfigKeys.REQUIRE_ENTITY_CLASS_CONSISTENCY].value as boolean;
            this.requireDisposalYearConsistency = configValues[StorageUnitsConfigKeys.REQUIRE_DISPOSAL_YEAR_CONSISTENCY].value as boolean;
            this.forceRequiredDisposalSchedule = configValues[StorageUnitsConfigKeys.FORCE_REQUIRED_DISPOSAL_SCHEDULE].value as boolean;
            this.storeDocumentsSeparatelyUntil = new Date(configValues[StorageUnitsConfigKeys.STORE_DOCUMENTS_SEPARATELY_UNTIL].value as string);
            this.filingOfficeHeartbeat = configValues[MiscConfigKey.FILING_OFFICE_RECEIVE_MESSAGE_TIMER].value as number;
            this.elasticMaxSearchResultWindow = configValues[MiscConfigKey.ELASTIC_MAX_SEARCH_RESULT_WINDOW].value as number;
            this.allowAgendaTransfer = configValues[AgendaTransferConfigKeys.AGENDA_TRANSFER_IS_ACTIVE].value as boolean;
          }
          catch (e) {
            if (e instanceof Error) {
              throw new Error(`Failed to load a critical config key. ${e.stack}`);
            }
          }
        }
        else {
          console.warn('Authorization incomplete, did not load application config values.');
        }
      }),
      castStream<void>(),
    );
  }

}
