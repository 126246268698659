/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { PageUserDto } from '../models/page-user-dto';
import { userAdminChangePassword } from '../fn/user/user-admin-change-password';
import { UserAdminChangePassword$Params } from '../fn/user/user-admin-change-password';
import { UserDto } from '../models/user-dto';
import { userFindAll } from '../fn/user/user-find-all';
import { UserFindAll$Params } from '../fn/user/user-find-all';
import { userFindAllForFunctionalPosition } from '../fn/user/user-find-all-for-functional-position';
import { UserFindAllForFunctionalPosition$Params } from '../fn/user/user-find-all-for-functional-position';
import { userFindById } from '../fn/user/user-find-by-id';
import { UserFindById$Params } from '../fn/user/user-find-by-id';
import { userFindByPersonalNumber } from '../fn/user/user-find-by-personal-number';
import { UserFindByPersonalNumber$Params } from '../fn/user/user-find-by-personal-number';
import { userFindByUsername } from '../fn/user/user-find-by-username';
import { UserFindByUsername$Params } from '../fn/user/user-find-by-username';
import { userFindForFunctionalPositionSubstitution } from '../fn/user/user-find-for-functional-position-substitution';
import { UserFindForFunctionalPositionSubstitution$Params } from '../fn/user/user-find-for-functional-position-substitution';
import { userFindUserByDefaultFunctionalPosition } from '../fn/user/user-find-user-by-default-functional-position';
import { UserFindUserByDefaultFunctionalPosition$Params } from '../fn/user/user-find-user-by-default-functional-position';
import { userGetUserDetails } from '../fn/user/user-get-user-details';
import { UserGetUserDetails$Params } from '../fn/user/user-get-user-details';
import { userChangePassword } from '../fn/user/user-change-password';
import { UserChangePassword$Params } from '../fn/user/user-change-password';
import { userSearch } from '../fn/user/user-search';
import { UserSearch$Params } from '../fn/user/user-search';


/**
 * Controller with operations over user details
 */
@Injectable({ providedIn: 'root' })
export class ApiUserService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `userChangePassword()` */
  static readonly UserChangePasswordPath = '/user/change-password';

  /**
   * User Password change.
   *
   * Changes user password
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userChangePassword()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userChangePassword$Response(params: UserChangePassword$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return userChangePassword(this.http, this.rootUrl, params, context);
  }

  /**
   * User Password change.
   *
   * Changes user password
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userChangePassword$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userChangePassword(params: UserChangePassword$Params, context?: HttpContext): Observable<void> {
    return this.userChangePassword$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `userAdminChangePassword()` */
  static readonly UserAdminChangePasswordPath = '/user/admin-change-password';

  /**
   * User Password change.
   *
   * Changes user password
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userAdminChangePassword()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userAdminChangePassword$Response(params: UserAdminChangePassword$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return userAdminChangePassword(this.http, this.rootUrl, params, context);
  }

  /**
   * User Password change.
   *
   * Changes user password
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userAdminChangePassword$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userAdminChangePassword(params: UserAdminChangePassword$Params, context?: HttpContext): Observable<void> {
    return this.userAdminChangePassword$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `userFindById()` */
  static readonly UserFindByIdPath = '/user/{id}';

  /**
   * Find user based on its ID
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  userFindById$Response(params: UserFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<UserDto>> {
    return userFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * Find user based on its ID
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userFindById(params: UserFindById$Params, context?: HttpContext): Observable<UserDto> {
    return this.userFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserDto>): UserDto => r.body)
    );
  }

  /** Path part for operation `userFindForFunctionalPositionSubstitution()` */
  static readonly UserFindForFunctionalPositionSubstitutionPath = '/user/users-for-substitution/{fmId}';

  /**
   * Functional position to find users for
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userFindForFunctionalPositionSubstitution()` instead.
   *
   * This method doesn't expect any request body.
   */
  userFindForFunctionalPositionSubstitution$Response(params: UserFindForFunctionalPositionSubstitution$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<UserDto>>> {
    return userFindForFunctionalPositionSubstitution(this.http, this.rootUrl, params, context);
  }

  /**
   * Functional position to find users for
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userFindForFunctionalPositionSubstitution$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userFindForFunctionalPositionSubstitution(params: UserFindForFunctionalPositionSubstitution$Params, context?: HttpContext): Observable<Array<UserDto>> {
    return this.userFindForFunctionalPositionSubstitution$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<UserDto>>): Array<UserDto> => r.body)
    );
  }

  /** Path part for operation `userGetUserDetails()` */
  static readonly UserGetUserDetailsPath = '/user/user-details';

  /**
   * Find user based on current authenticated user
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userGetUserDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  userGetUserDetails$Response(params?: UserGetUserDetails$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<UserDto>> {
    return userGetUserDetails(this.http, this.rootUrl, params, context);
  }

  /**
   * Find user based on current authenticated user
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userGetUserDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userGetUserDetails(params?: UserGetUserDetails$Params|null|undefined, context?: HttpContext): Observable<UserDto> {
    return this.userGetUserDetails$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserDto>): UserDto => r.body)
    );
  }

  /** Path part for operation `userSearch()` */
  static readonly UserSearchPath = '/user/search-api';

  /**
   * Finds users based on search request
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  userSearch$Response(params?: UserSearch$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageUserDto>> {
    return userSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds users based on search request
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userSearch(params?: UserSearch$Params|null|undefined, context?: HttpContext): Observable<PageUserDto> {
    return this.userSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageUserDto>): PageUserDto => r.body)
    );
  }

  /** Path part for operation `userFindUserByDefaultFunctionalPosition()` */
  static readonly UserFindUserByDefaultFunctionalPositionPath = '/user/find-by-default-functional-position/{id}';

  /**
   * Find user based on default functional position id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userFindUserByDefaultFunctionalPosition()` instead.
   *
   * This method doesn't expect any request body.
   */
  userFindUserByDefaultFunctionalPosition$Response(params: UserFindUserByDefaultFunctionalPosition$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<UserDto>>> {
    return userFindUserByDefaultFunctionalPosition(this.http, this.rootUrl, params, context);
  }

  /**
   * Find user based on default functional position id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userFindUserByDefaultFunctionalPosition$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userFindUserByDefaultFunctionalPosition(params: UserFindUserByDefaultFunctionalPosition$Params, context?: HttpContext): Observable<Array<UserDto>> {
    return this.userFindUserByDefaultFunctionalPosition$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<UserDto>>): Array<UserDto> => r.body)
    );
  }

  /** Path part for operation `userFindAll()` */
  static readonly UserFindAllPath = '/user/find-all';

  /**
   * Find user based on current authenticated user
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  userFindAll$Response(params?: UserFindAll$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageUserDto>> {
    return userFindAll(this.http, this.rootUrl, params, context);
  }

  /**
   * Find user based on current authenticated user
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userFindAll(params?: UserFindAll$Params|null|undefined, context?: HttpContext): Observable<PageUserDto> {
    return this.userFindAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageUserDto>): PageUserDto => r.body)
    );
  }

  /** Path part for operation `userFindAllForFunctionalPosition()` */
  static readonly UserFindAllForFunctionalPositionPath = '/user/find-all-for-functional-position/{id}';

  /**
   * Functional position to find users for
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userFindAllForFunctionalPosition()` instead.
   *
   * This method doesn't expect any request body.
   */
  userFindAllForFunctionalPosition$Response(params: UserFindAllForFunctionalPosition$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<UserDto>>> {
    return userFindAllForFunctionalPosition(this.http, this.rootUrl, params, context);
  }

  /**
   * Functional position to find users for
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userFindAllForFunctionalPosition$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userFindAllForFunctionalPosition(params: UserFindAllForFunctionalPosition$Params, context?: HttpContext): Observable<Array<UserDto>> {
    return this.userFindAllForFunctionalPosition$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<UserDto>>): Array<UserDto> => r.body)
    );
  }

  /** Path part for operation `userFindByUsername()` */
  static readonly UserFindByUsernamePath = '/user/by-username/{username}';

  /**
   * Finds user by their username.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userFindByUsername()` instead.
   *
   * This method doesn't expect any request body.
   */
  userFindByUsername$Response(params: UserFindByUsername$Params, context?: HttpContext): Observable<StrictHttpResponse<UserDto>> {
    return userFindByUsername(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds user by their username.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userFindByUsername$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userFindByUsername(params: UserFindByUsername$Params, context?: HttpContext): Observable<UserDto> {
    return this.userFindByUsername$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserDto>): UserDto => r.body)
    );
  }

  /** Path part for operation `userFindByPersonalNumber()` */
  static readonly UserFindByPersonalNumberPath = '/user/by-personal-number/{personalNumber}';

  /**
   * Finds user by their personal number.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userFindByPersonalNumber()` instead.
   *
   * This method doesn't expect any request body.
   */
  userFindByPersonalNumber$Response(params: UserFindByPersonalNumber$Params, context?: HttpContext): Observable<StrictHttpResponse<UserDto>> {
    return userFindByPersonalNumber(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds user by their personal number.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userFindByPersonalNumber$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userFindByPersonalNumber(params: UserFindByPersonalNumber$Params, context?: HttpContext): Observable<UserDto> {
    return this.userFindByPersonalNumber$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserDto>): UserDto => r.body)
    );
  }

}
